import { Chip, MenuItem } from '@mui/material';
import React from 'react';
import { Select, showErrorOnBlur } from 'mui-rff';
import FlagNL from '!svg-react-loader?name=FlagNL!assets/flags/nl.svg';
import FlagEN from '!svg-react-loader?name=FlagEN!assets/flags/en.svg';
import FlagDE from '!svg-react-loader?name=FlagDE!assets/flags/de.svg';
export const selectMap = {};
export const SelectComponent = (props) => {
    const { name, label, required, disabled, onSelect, className, options, multiple, selectColor, selectCountry } = props;
    return (React.createElement("div", { className: `MuiCustomSelectBase-root ${className}` },
        React.createElement(Select, { name: name, multiple: multiple, disabled: disabled, required: required, label: label, showError: showErrorOnBlur, renderValue: (selected) => {
                const itemSelected = options.find((item) => item.value === selected);
                return (multiple
                    ? selected.map((value) => {
                        const itemSelected = options.find((item) => item.value === value);
                        return (React.createElement(Chip, { key: itemSelected?.value, label: itemSelected?.text }));
                    })
                    : selectColor ? React.createElement(React.Fragment, null,
                        React.createElement("span", { className: 'select-list-color-block', style: { background: `#${itemSelected?.color}` } }),
                        itemSelected?.text)
                        : selectCountry ? React.createElement(React.Fragment, null,
                            React.createElement("span", { className: 'select-list-country-flag' }, itemSelected?.value === 'nl' ? React.createElement(FlagNL, null)
                                : itemSelected?.value === 'en' ? React.createElement(FlagEN, null)
                                    : itemSelected?.value === 'de' && React.createElement(FlagDE, null)),
                            itemSelected?.text)
                            : itemSelected?.text);
            } }, options && options.map((item) => {
            return (React.createElement(MenuItem, { key: item.value, value: item.value, onClick: onSelect ? () => onSelect(item.value) : null },
                selectCountry &&
                    React.createElement("span", { className: 'select-list-country-flag' }, item.value === 'nl' ? React.createElement(FlagNL, null)
                        : item.value === 'en' ? React.createElement(FlagEN, null)
                            : item.value === 'de' && React.createElement(FlagDE, null)),
                selectColor && React.createElement("span", { className: 'select-list-color-block', style: { background: `#${item.color}` } }),
                item.text));
        }))));
};
