import { toastrError, toastrSuccess } from '@elementComponents/Toastr';
import { Button } from '@mui/material';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SwitchComponent } from '@formComponents/Switch';
import { acceptLicence } from '../../state/api';
import { fetchUser } from 'app/admin/users/state/slice';
const LicenseModalUser = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { onClickLogout, onCloseModal } = props;
    const onClickAccept = async () => {
        try {
            const response = await acceptLicence();
            if (response.id && response.hasAcceptedLicence === true) {
                fetchUser(response.id);
                toastrSuccess(t('main.success'), t('auth.acceptedLicenceSuccess'));
                onCloseModal('', 'closeButtonClick');
                history.push('/dashboard');
            }
        }
        catch (e) {
            console.error(e);
            toastrError(t('main.error'), t('auth.acceptedLicenceFailed'));
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("p", null,
            t('auth.licenseText1'),
            React.createElement("a", { rel: 'noreferrer', target: '_blank', className: 'text-btn', href: 'https://www.stienen.com/algemene-voorwaarden/' }, t('auth.licenseText2')),
            t('auth.licenseText3')),
        React.createElement(Form, { onSubmit: () => { }, render: ({ values }) => (React.createElement("form", { className: 'mt2' },
                React.createElement(SwitchComponent, { positiveNegativeSwitch: true, name: 'Accept', data: {
                        label: t('auth.acceptText'),
                        value: true
                    } }),
                React.createElement("div", { className: 'modal-action-buttons' },
                    React.createElement(Button, { className: 'text-btn', onClick: () => onClickLogout() }, t('main.logout')),
                    React.createElement(Button, { variant: 'contained', onClick: () => onClickAccept(), disabled: !values ||
                            !values.Accept }, t('auth.continue'))))) })));
};
export default LicenseModalUser;
