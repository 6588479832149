import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import { Button, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import { BooleanDots } from './BooleanDots';
import { useTranslation } from 'react-i18next';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import IndeterminateCheckBoxRoundedIcon from '@mui/icons-material/IndeterminateCheckBoxRounded';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { format } from 'date-fns';
import SearchBar from '@mkyy/mui-search-bar';
import { Icon } from 'semantic-ui-react';
import isEqual from 'lodash/isEqual';
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
    const { t } = useTranslation();
    const { searchBar, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, showCheckboxes, actions, searched, requestSearch, cancelSearch } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (React.createElement(TableHead, null,
        React.createElement(TableRow, null,
            showCheckboxes &&
                React.createElement(TableCell, { padding: 'checkbox' },
                    React.createElement(Checkbox, { color: 'primary', icon: React.createElement(CheckBoxOutlineBlankRoundedIcon, null), checkedIcon: React.createElement(CheckBoxRoundedIcon, null), indeterminateIcon: React.createElement(IndeterminateCheckBoxRoundedIcon, null), indeterminate: numSelected > 0 && numSelected < rowCount, checked: rowCount > 0 && numSelected === rowCount, onChange: onSelectAllClick })),
            props.headers.map((headCell) => (React.createElement(TableCell, { key: headCell.id, align: headCell.numeric ? 'right' : 'left', sortDirection: orderBy === headCell.id ? order : false },
                React.createElement(TableSortLabel, { active: orderBy === headCell.id, direction: orderBy === headCell.id ? order : 'asc', onClick: createSortHandler(headCell.id), IconComponent: ArrowDropUpRoundedIcon },
                    headCell.label,
                    orderBy === headCell.id ? (React.createElement(Box, { component: 'span', sx: visuallyHidden }, order === 'desc' ? 'sorted descending' : 'sorted ascending')) : null)))),
            actions && (React.createElement(TableCell, null, t('main.actions')))),
        searchBar &&
            React.createElement(TableRow, { className: 'table-row-search' },
                React.createElement(TableCell, { colSpan: 16 },
                    React.createElement(SearchBar, { placeholder: t('main.search'), value: searched, onChange: (searchVal) => requestSearch(searchVal), onCancelResearch: () => cancelSearch() })))));
}
function EnhancedTableToolbar(props) {
    const { numSelected, title, onDeleteSelection, selected, headers } = props;
    const { t } = useTranslation();
    const fileName = `${format(new Date(), 'yyyy-MM-dd-HH-mm')}-${title?.toLowerCase()}.csv`;
    const csvHeaders = headers && headers.map((header) => {
        return {
            key: header.id,
            label: header.label
        };
    });
    return (React.createElement(Toolbar, { className: 'selected-toolbar', sx: {
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 }
        } },
        React.createElement(Button, { className: 'mr-half', variant: 'contained', disabled: numSelected === 0 },
            React.createElement(CSVLink, { separator: ';', headers: csvHeaders, data: selected, filename: fileName, target: '_blank' }, t('main.exportAsCsv'))),
        React.createElement(Button, { variant: 'contained', disabled: numSelected === 0, onClick: () => onDeleteSelection() }, t('main.deleteSelection')),
        numSelected > 0 && (React.createElement(Typography, { sx: { flex: '1 1 100%' }, color: 'inherit', variant: 'subtitle1', component: 'div' }, `${numSelected} ${title} ${t('main.selected')}`))));
}
const StienenTable = (props) => {
    const { t } = useTranslation();
    const { headers, rows, defaultOrder, searchBar, isLoading, defaultOrderDesc, negativeHeaderBorder, showCheckboxes, title, numberOfRows, linkUrl, linkUrlValue, actions } = props;
    const [order, setOrder] = React.useState(defaultOrderDesc ? 'desc' : 'asc');
    const [orderBy, setOrderBy] = React.useState(defaultOrder ? defaultOrder : '');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(numberOfRows ? numberOfRows : 10);
    const [searchRows, setSearchRows] = React.useState(rows);
    const [previousRows, setPreviousRows] = React.useState(rows);
    const [searched, setSearched] = React.useState('');
    React.useEffect(() => {
        const isSameArray = isEqual(rows, previousRows);
        !isSameArray && (setSearchRows(rows),
            setPreviousRows(rows));
    }, [rows]);
    const requestSearch = (searchedVal) => {
        const filteredRows = rows.filter((row) => {
            const result = headers.map((header) => {
                const valueString = typeof row[header.id] === 'number' ||
                    typeof row[header.id] === 'string' ? row[header.id].toString() : '';
                return valueString && valueString.toLowerCase().includes(searchedVal.toLowerCase());
            });
            return result && result.includes(true) && row;
        });
        setSearchRows(filteredRows);
    };
    const cancelSearch = () => {
        setSearched('');
        requestSearch(searched);
    };
    const handleRequestSort = (_event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows;
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleClick = (_event, row) => {
        const selectedIndex = selected.indexOf(row);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, row);
        }
        else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        }
        else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        }
        else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };
    const onDeleteSelection = () => {
        setSelected([]);
    };
    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const isSelected = (row) => selected.indexOf(row) !== -1;
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - searchRows.length) : 0;
    const visibleRows = React.useMemo(() => stableSort(searchRows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), [order, orderBy, page, rowsPerPage, searchRows, rows]);
    return (React.createElement(Box, { sx: { width: '100%' }, className: 'stienen-table' },
        React.createElement("div", { className: `header-border ${negativeHeaderBorder && 'negative'}` }),
        React.createElement(Paper, { sx: { width: '100%' } },
            React.createElement(TableContainer, null,
                React.createElement(Table, null,
                    React.createElement(EnhancedTableHead, { numSelected: selected.length, order: order, orderBy: orderBy, onSelectAllClick: handleSelectAllClick, onRequestSort: handleRequestSort, rowCount: searchRows.length, headers: headers, searchBar: searchBar, showCheckboxes: showCheckboxes, actions: actions, searched: searched, requestSearch: requestSearch, cancelSearch: cancelSearch }),
                    React.createElement(TableBody, null,
                        isLoading ?
                            React.createElement(TableRow, { tabIndex: -1, className: 'loading-table-row' },
                                React.createElement(TableCell, { colSpan: 16, align: 'center' },
                                    React.createElement(Icon, { loading: true, name: 'spinner', size: 'large' })))
                            : searchRows.length > 0 ? visibleRows.map((row, key) => {
                                const isItemSelected = isSelected(row);
                                const labelId = `enhanced-table-checkbox-${key}`;
                                return (React.createElement(TableRow, { hover: true, "aria-checked": showCheckboxes && isItemSelected, tabIndex: -1, key: labelId, selected: showCheckboxes && isItemSelected, sx: { cursor: linkUrl ? 'pointer' : 'default' } },
                                    showCheckboxes &&
                                        React.createElement(TableCell, { padding: 'checkbox' },
                                            React.createElement(Checkbox, { icon: React.createElement(CheckBoxOutlineBlankRoundedIcon, null), checkedIcon: React.createElement(CheckBoxRoundedIcon, null), checked: isItemSelected, onClick: (event) => handleClick(event, row), inputProps: {
                                                    'aria-labelledby': labelId,
                                                } })),
                                    headers.map((column, key) => {
                                        return (React.createElement(TableCell, { key: key, id: labelId, scope: 'row', align: column.numeric ? 'right' : 'left', component: linkUrl ? Link : 'td', to: `${linkUrl}${linkUrlValue === 'uuid' ? row.uuid : row.id}` }, column.booleanDots ?
                                            React.createElement(BooleanDots, { value: row[column.id] }) :
                                            row[column.id]));
                                    }),
                                    actions && (React.createElement(TableCell, { className: 'table-action-cell' }, actions.map((action, key) => {
                                        return (action.tooltip && !action.onlyShowIfColumnIsFalse)
                                            || (action.tooltip && action.onlyShowIfColumnIsFalse && !row[action.onlyShowIfColumnIsFalse])
                                            ? (React.createElement(Tooltip, { arrow: true, key: key, placement: 'top', title: action.tooltip },
                                                React.createElement(IconButton, { className: 'table-action-btn', onClick: action.onClick ? () => action.onClick(row) : undefined, component: action.linkUrl ? Link : 'button', to: `${action.linkUrl}${action.linkUrlValue === 'uuid' ? row.uuid : row.id}` }, action.icon))) : action.onlyShowIfColumnIsTrue && row[action.onlyShowIfColumnIsTrue] && action.button && (React.createElement(Button, { key: key, size: 'small', variant: 'contained', onClick: action.onClick ? () => action.onClick(row) : undefined }, action.button));
                                    })))));
                            })
                                : (React.createElement(TableRow, null,
                                    React.createElement(TableCell, { colSpan: 16, className: 'no-data-table-row' }, t('main.noDataTableText', {
                                        title: title?.toLowerCase()
                                    })))),
                        emptyRows > 0 && (React.createElement(TableRow, null,
                            React.createElement(TableCell, { colSpan: 16 })))))),
            showCheckboxes &&
                React.createElement(EnhancedTableToolbar, { numSelected: selected.length, selected: selected, title: title, onDeleteSelection: () => onDeleteSelection(), headers: headers }),
            React.createElement(TablePagination, { rowsPerPageOptions: [5, 10, 25, 50, 100], component: 'div', count: searchRows.length, rowsPerPage: rowsPerPage, labelRowsPerPage: t('main.numberOfRows'), page: page, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage }))));
};
export default StienenTable;
