export const FakeAnalyticsData = [
    {
        'deviceId': '70025e99-d62c-44f3-b85f-c54d77bd0b19',
        'varName': 'R1_Tregel',
        'data': [
            {
                'dateTime': '2024-10-14T00:00:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T00:10:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T00:20:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T00:30:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T00:40:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T00:50:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T01:00:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T01:10:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T01:20:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T01:30:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T01:40:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T01:50:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T02:00:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T02:10:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T02:20:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T02:30:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T02:40:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T02:50:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T03:00:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T03:10:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T03:20:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T03:30:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T03:40:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T03:50:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T04:00:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T04:10:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T04:20:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T04:30:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T04:40:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T04:50:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T05:00:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T05:10:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T05:20:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T05:30:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T05:40:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T05:50:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T06:00:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T06:10:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T06:20:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T06:30:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T06:40:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T06:50:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T07:00:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T07:10:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T07:20:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T07:30:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T07:40:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T07:50:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T08:00:00',
                'value': 21.5
            },
            {
                'dateTime': '2024-10-14T08:10:00',
                'value': 21.4
            },
            {
                'dateTime': '2024-10-14T08:20:00',
                'value': 21.4
            },
            {
                'dateTime': '2024-10-14T08:30:00',
                'value': 21.3
            },
            {
                'dateTime': '2024-10-14T08:40:00',
                'value': 21.3
            },
            {
                'dateTime': '2024-10-14T08:50:00',
                'value': 21.2
            },
            {
                'dateTime': '2024-10-14T09:00:00',
                'value': 21.2
            },
            {
                'dateTime': '2024-10-14T09:10:00',
                'value': 21.1
            },
            {
                'dateTime': '2024-10-14T09:20:00',
                'value': 21.1
            },
            {
                'dateTime': '2024-10-14T09:30:00',
                'value': 21
            },
            {
                'dateTime': '2024-10-14T09:40:00',
                'value': 21
            },
            {
                'dateTime': '2024-10-14T09:50:00',
                'value': 20.9
            },
            {
                'dateTime': '2024-10-14T10:00:00',
                'value': 20.8
            },
            {
                'dateTime': '2024-10-14T10:10:00',
                'value': 20.8
            },
            {
                'dateTime': '2024-10-14T10:20:00',
                'value': 20.7
            },
            {
                'dateTime': '2024-10-14T10:30:00',
                'value': 20.7
            },
            {
                'dateTime': '2024-10-14T10:40:00',
                'value': 20.6
            },
            {
                'dateTime': '2024-10-14T10:50:00',
                'value': 20.6
            },
            {
                'dateTime': '2024-10-14T11:00:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T11:10:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T11:20:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T11:30:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T11:40:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T11:50:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T12:00:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T12:10:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T12:20:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T12:30:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T12:40:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T12:50:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T13:00:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T13:10:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T13:20:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T13:30:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T13:40:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T13:50:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T14:00:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T14:10:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T14:20:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T14:30:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T14:40:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T14:50:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T15:00:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T15:10:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T15:20:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T15:30:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T15:40:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T15:50:00',
                'value': 20.5
            },
            {
                'dateTime': '2024-10-14T16:00:00',
                'value': 20.5
            }
        ]
    },
    {
        'deviceId': '70025e99-d62c-44f3-b85f-c54d77bd0b19',
        'varName': 'R1_Tmom',
        'data': [
            {
                'dateTime': '2024-10-14T00:00:00',
                'value': 23.4
            },
            {
                'dateTime': '2024-10-14T00:10:00',
                'value': 23.4
            },
            {
                'dateTime': '2024-10-14T00:20:00',
                'value': 23.4
            },
            {
                'dateTime': '2024-10-14T00:30:00',
                'value': 23.4
            },
            {
                'dateTime': '2024-10-14T00:40:00',
                'value': 23.4
            },
            {
                'dateTime': '2024-10-14T00:50:00',
                'value': 23.4
            },
            {
                'dateTime': '2024-10-14T01:00:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T01:10:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T01:20:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T01:30:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T01:40:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T01:50:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T02:00:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T02:10:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T02:20:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T02:30:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T02:40:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T02:50:00',
                'value': 23.4
            },
            {
                'dateTime': '2024-10-14T03:00:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T03:10:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T03:20:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T03:30:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T03:40:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T03:50:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T04:00:00',
                'value': 23.5
            },
            {
                'dateTime': '2024-10-14T04:10:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T04:20:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T04:30:00',
                'value': 23.4
            },
            {
                'dateTime': '2024-10-14T04:40:00',
                'value': 23.4
            },
            {
                'dateTime': '2024-10-14T04:50:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T05:00:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T05:10:00',
                'value': 23.4
            },
            {
                'dateTime': '2024-10-14T05:20:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T05:30:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T05:40:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T05:50:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T06:00:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T06:10:00',
                'value': 23.4
            },
            {
                'dateTime': '2024-10-14T06:20:00',
                'value': 23.4
            },
            {
                'dateTime': '2024-10-14T06:30:00',
                'value': 23.4
            },
            {
                'dateTime': '2024-10-14T06:40:00',
                'value': 23.5
            },
            {
                'dateTime': '2024-10-14T06:50:00',
                'value': 23.5
            },
            {
                'dateTime': '2024-10-14T07:00:00',
                'value': 23.9
            },
            {
                'dateTime': '2024-10-14T07:10:00',
                'value': 23.9
            },
            {
                'dateTime': '2024-10-14T07:20:00',
                'value': 23.8
            },
            {
                'dateTime': '2024-10-14T07:30:00',
                'value': 23.6
            },
            {
                'dateTime': '2024-10-14T07:40:00',
                'value': 23.6
            },
            {
                'dateTime': '2024-10-14T07:50:00',
                'value': 23.6
            },
            {
                'dateTime': '2024-10-14T08:00:00',
                'value': 23.8
            },
            {
                'dateTime': '2024-10-14T08:10:00',
                'value': 23.6
            },
            {
                'dateTime': '2024-10-14T08:20:00',
                'value': 23.5
            },
            {
                'dateTime': '2024-10-14T08:30:00',
                'value': 23.5
            },
            {
                'dateTime': '2024-10-14T08:40:00',
                'value': 23.4
            },
            {
                'dateTime': '2024-10-14T08:50:00',
                'value': 23.4
            },
            {
                'dateTime': '2024-10-14T09:00:00',
                'value': 23.4
            },
            {
                'dateTime': '2024-10-14T09:10:00',
                'value': 23.4
            },
            {
                'dateTime': '2024-10-14T09:20:00',
                'value': 23.5
            },
            {
                'dateTime': '2024-10-14T09:30:00',
                'value': 23.5
            },
            {
                'dateTime': '2024-10-14T09:40:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T09:50:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T10:00:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T10:10:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T10:20:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T10:30:00',
                'value': 23.2
            },
            {
                'dateTime': '2024-10-14T10:40:00',
                'value': 23.2
            },
            {
                'dateTime': '2024-10-14T10:50:00',
                'value': 23.1
            },
            {
                'dateTime': '2024-10-14T11:00:00',
                'value': 23.1
            },
            {
                'dateTime': '2024-10-14T11:10:00',
                'value': 23.1
            },
            {
                'dateTime': '2024-10-14T11:20:00',
                'value': 23.1
            },
            {
                'dateTime': '2024-10-14T11:30:00',
                'value': 23.1
            },
            {
                'dateTime': '2024-10-14T11:40:00',
                'value': 23.1
            },
            {
                'dateTime': '2024-10-14T11:50:00',
                'value': 23.2
            },
            {
                'dateTime': '2024-10-14T12:00:00',
                'value': 23.2
            },
            {
                'dateTime': '2024-10-14T12:10:00',
                'value': 23.1
            },
            {
                'dateTime': '2024-10-14T12:20:00',
                'value': 23.1
            },
            {
                'dateTime': '2024-10-14T12:30:00',
                'value': 23.1
            },
            {
                'dateTime': '2024-10-14T12:40:00',
                'value': 23.1
            },
            {
                'dateTime': '2024-10-14T12:50:00',
                'value': 23.1
            },
            {
                'dateTime': '2024-10-14T13:00:00',
                'value': 23.2
            },
            {
                'dateTime': '2024-10-14T13:10:00',
                'value': 23.2
            },
            {
                'dateTime': '2024-10-14T13:20:00',
                'value': 23.2
            },
            {
                'dateTime': '2024-10-14T13:30:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T13:40:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T13:50:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T14:00:00',
                'value': 23.3
            },
            {
                'dateTime': '2024-10-14T14:10:00',
                'value': 23.4
            },
            {
                'dateTime': '2024-10-14T14:20:00',
                'value': 23.4
            },
            {
                'dateTime': '2024-10-14T14:30:00',
                'value': 23.5
            },
            {
                'dateTime': '2024-10-14T14:40:00',
                'value': 23.4
            },
            {
                'dateTime': '2024-10-14T14:50:00',
                'value': 23.5
            },
            {
                'dateTime': '2024-10-14T15:00:00',
                'value': 23.5
            },
            {
                'dateTime': '2024-10-14T15:10:00',
                'value': 23.5
            },
            {
                'dateTime': '2024-10-14T15:20:00',
                'value': 23.5
            },
            {
                'dateTime': '2024-10-14T15:30:00',
                'value': 23.7
            },
            {
                'dateTime': '2024-10-14T15:40:00',
                'value': 23.9
            },
            {
                'dateTime': '2024-10-14T15:50:00',
                'value': 23.8
            },
            {
                'dateTime': '2024-10-14T16:00:00',
                'value': 23.7
            }
        ]
    },
    {
        'deviceId': '70025e99-d62c-44f3-b85f-c54d77bd0b19',
        'varName': 'R1_BAND',
        'data': [
            {
                'dateTime': '2024-10-14T00:00:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T00:10:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T00:20:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T00:30:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T00:40:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T00:50:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T01:00:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T01:10:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T01:20:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T01:30:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T01:40:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T01:50:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T02:00:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T02:10:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T02:20:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T02:30:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T02:40:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T02:50:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T03:00:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T03:10:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T03:20:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T03:30:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T03:40:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T03:50:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T04:00:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T04:10:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T04:20:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T04:30:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T04:40:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T04:50:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T05:00:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T05:10:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T05:20:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T05:30:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T05:40:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T05:50:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T06:00:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T06:10:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T06:20:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T06:30:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T06:40:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T06:50:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T07:00:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T07:10:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T07:20:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T07:30:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T07:40:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T07:50:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T08:00:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T08:10:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T08:20:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T08:30:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T08:40:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T08:50:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T09:00:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T09:10:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T09:20:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T09:30:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T09:40:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T09:50:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T10:00:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T10:10:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T10:20:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T10:30:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T10:40:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T10:50:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T11:00:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T11:10:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T11:20:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T11:30:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T11:40:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T11:50:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T12:00:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T12:10:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T12:20:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T12:30:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T12:40:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T12:50:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T13:00:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T13:10:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T13:20:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T13:30:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T13:40:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T13:50:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T14:00:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T14:10:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T14:20:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T14:30:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T14:40:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T14:50:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T15:00:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T15:10:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T15:20:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T15:30:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T15:40:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T15:50:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T16:00:00',
                'value': 5.8
            }
        ]
    },
    {
        'deviceId': '70025e99-d62c-44f3-b85f-c54d77bd0b19',
        'varName': 'R1_VENT',
        'data': [
            {
                'dateTime': '2024-10-14T00:00:00',
                'value': 21
            },
            {
                'dateTime': '2024-10-14T00:10:00',
                'value': 23
            },
            {
                'dateTime': '2024-10-14T00:20:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T00:30:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T00:40:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T00:50:00',
                'value': 28
            },
            {
                'dateTime': '2024-10-14T01:00:00',
                'value': 26
            },
            {
                'dateTime': '2024-10-14T01:10:00',
                'value': 22
            },
            {
                'dateTime': '2024-10-14T01:20:00',
                'value': 22
            },
            {
                'dateTime': '2024-10-14T01:30:00',
                'value': 22
            },
            {
                'dateTime': '2024-10-14T01:40:00',
                'value': 26
            },
            {
                'dateTime': '2024-10-14T01:50:00',
                'value': 22
            },
            {
                'dateTime': '2024-10-14T02:00:00',
                'value': 20
            },
            {
                'dateTime': '2024-10-14T02:10:00',
                'value': 23
            },
            {
                'dateTime': '2024-10-14T02:20:00',
                'value': 21
            },
            {
                'dateTime': '2024-10-14T02:30:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T02:40:00',
                'value': 26
            },
            {
                'dateTime': '2024-10-14T02:50:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T03:00:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T03:10:00',
                'value': 23
            },
            {
                'dateTime': '2024-10-14T03:20:00',
                'value': 25
            },
            {
                'dateTime': '2024-10-14T03:30:00',
                'value': 25
            },
            {
                'dateTime': '2024-10-14T03:40:00',
                'value': 22
            },
            {
                'dateTime': '2024-10-14T03:50:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T04:00:00',
                'value': 25
            },
            {
                'dateTime': '2024-10-14T04:10:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T04:20:00',
                'value': 26
            },
            {
                'dateTime': '2024-10-14T04:30:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T04:40:00',
                'value': 21
            },
            {
                'dateTime': '2024-10-14T04:50:00',
                'value': 25
            },
            {
                'dateTime': '2024-10-14T05:00:00',
                'value': 22
            },
            {
                'dateTime': '2024-10-14T05:10:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T05:20:00',
                'value': 25
            },
            {
                'dateTime': '2024-10-14T05:30:00',
                'value': 23
            },
            {
                'dateTime': '2024-10-14T05:40:00',
                'value': 28
            },
            {
                'dateTime': '2024-10-14T05:50:00',
                'value': 25
            },
            {
                'dateTime': '2024-10-14T06:00:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T06:10:00',
                'value': 25
            },
            {
                'dateTime': '2024-10-14T06:20:00',
                'value': 26
            },
            {
                'dateTime': '2024-10-14T06:30:00',
                'value': 29
            },
            {
                'dateTime': '2024-10-14T06:40:00',
                'value': 29
            },
            {
                'dateTime': '2024-10-14T06:50:00',
                'value': 25
            },
            {
                'dateTime': '2024-10-14T07:00:00',
                'value': 25
            },
            {
                'dateTime': '2024-10-14T07:10:00',
                'value': 30
            },
            {
                'dateTime': '2024-10-14T07:20:00',
                'value': 28
            },
            {
                'dateTime': '2024-10-14T07:30:00',
                'value': 23
            },
            {
                'dateTime': '2024-10-14T07:40:00',
                'value': 28
            },
            {
                'dateTime': '2024-10-14T07:50:00',
                'value': 27
            },
            {
                'dateTime': '2024-10-14T08:00:00',
                'value': 30
            },
            {
                'dateTime': '2024-10-14T08:10:00',
                'value': 28
            },
            {
                'dateTime': '2024-10-14T08:20:00',
                'value': 22
            },
            {
                'dateTime': '2024-10-14T08:30:00',
                'value': 26
            },
            {
                'dateTime': '2024-10-14T08:40:00',
                'value': 27
            },
            {
                'dateTime': '2024-10-14T08:50:00',
                'value': 30
            },
            {
                'dateTime': '2024-10-14T09:00:00',
                'value': 30
            },
            {
                'dateTime': '2024-10-14T09:10:00',
                'value': 25
            },
            {
                'dateTime': '2024-10-14T09:20:00',
                'value': 28
            },
            {
                'dateTime': '2024-10-14T09:30:00',
                'value': 31
            },
            {
                'dateTime': '2024-10-14T09:40:00',
                'value': 33
            },
            {
                'dateTime': '2024-10-14T09:50:00',
                'value': 31
            },
            {
                'dateTime': '2024-10-14T10:00:00',
                'value': 31
            },
            {
                'dateTime': '2024-10-14T10:10:00',
                'value': 29
            },
            {
                'dateTime': '2024-10-14T10:20:00',
                'value': 29
            },
            {
                'dateTime': '2024-10-14T10:30:00',
                'value': 28
            },
            {
                'dateTime': '2024-10-14T10:40:00',
                'value': 32
            },
            {
                'dateTime': '2024-10-14T10:50:00',
                'value': 31
            },
            {
                'dateTime': '2024-10-14T11:00:00',
                'value': 30
            },
            {
                'dateTime': '2024-10-14T11:10:00',
                'value': 30
            },
            {
                'dateTime': '2024-10-14T11:20:00',
                'value': 32
            },
            {
                'dateTime': '2024-10-14T11:30:00',
                'value': 32
            },
            {
                'dateTime': '2024-10-14T11:40:00',
                'value': 29
            },
            {
                'dateTime': '2024-10-14T11:50:00',
                'value': 30
            },
            {
                'dateTime': '2024-10-14T12:00:00',
                'value': 31
            },
            {
                'dateTime': '2024-10-14T12:10:00',
                'value': 28
            },
            {
                'dateTime': '2024-10-14T12:20:00',
                'value': 32
            },
            {
                'dateTime': '2024-10-14T12:30:00',
                'value': 35
            },
            {
                'dateTime': '2024-10-14T12:40:00',
                'value': 35
            },
            {
                'dateTime': '2024-10-14T12:50:00',
                'value': 30
            },
            {
                'dateTime': '2024-10-14T13:00:00',
                'value': 28
            },
            {
                'dateTime': '2024-10-14T13:10:00',
                'value': 33
            },
            {
                'dateTime': '2024-10-14T13:20:00',
                'value': 30
            },
            {
                'dateTime': '2024-10-14T13:30:00',
                'value': 29
            },
            {
                'dateTime': '2024-10-14T13:40:00',
                'value': 32
            },
            {
                'dateTime': '2024-10-14T13:50:00',
                'value': 32
            },
            {
                'dateTime': '2024-10-14T14:00:00',
                'value': 30
            },
            {
                'dateTime': '2024-10-14T14:10:00',
                'value': 32
            },
            {
                'dateTime': '2024-10-14T14:20:00',
                'value': 33
            },
            {
                'dateTime': '2024-10-14T14:30:00',
                'value': 32
            },
            {
                'dateTime': '2024-10-14T14:40:00',
                'value': 38
            },
            {
                'dateTime': '2024-10-14T14:50:00',
                'value': 36
            },
            {
                'dateTime': '2024-10-14T15:00:00',
                'value': 34
            },
            {
                'dateTime': '2024-10-14T15:10:00',
                'value': 35
            },
            {
                'dateTime': '2024-10-14T15:20:00',
                'value': 35
            },
            {
                'dateTime': '2024-10-14T15:30:00',
                'value': 36
            },
            {
                'dateTime': '2024-10-14T15:40:00',
                'value': 35
            },
            {
                'dateTime': '2024-10-14T15:50:00',
                'value': 35
            },
            {
                'dateTime': '2024-10-14T16:00:00',
                'value': 36
            }
        ]
    },
    {
        'deviceId': '70025e99-d62c-44f3-b85f-c54d77bd0b19',
        'varName': 'R1_STAND',
        'data': [
            {
                'dateTime': '2024-10-14T00:00:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T00:10:00',
                'value': 25
            },
            {
                'dateTime': '2024-10-14T00:20:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T00:30:00',
                'value': 25
            },
            {
                'dateTime': '2024-10-14T00:40:00',
                'value': 25
            },
            {
                'dateTime': '2024-10-14T00:50:00',
                'value': 25
            },
            {
                'dateTime': '2024-10-14T01:00:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T01:10:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T01:20:00',
                'value': 23
            },
            {
                'dateTime': '2024-10-14T01:30:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T01:40:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T01:50:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T02:00:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T02:10:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T02:20:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T02:30:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T02:40:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T02:50:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T03:00:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T03:10:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T03:20:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T03:30:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T03:40:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T03:50:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T04:00:00',
                'value': 25
            },
            {
                'dateTime': '2024-10-14T04:10:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T04:20:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T04:30:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T04:40:00',
                'value': 25
            },
            {
                'dateTime': '2024-10-14T04:50:00',
                'value': 25
            },
            {
                'dateTime': '2024-10-14T05:00:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T05:10:00',
                'value': 25
            },
            {
                'dateTime': '2024-10-14T05:20:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T05:30:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T05:40:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T05:50:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T06:00:00',
                'value': 24
            },
            {
                'dateTime': '2024-10-14T06:10:00',
                'value': 25
            },
            {
                'dateTime': '2024-10-14T06:20:00',
                'value': 25
            },
            {
                'dateTime': '2024-10-14T06:30:00',
                'value': 26
            },
            {
                'dateTime': '2024-10-14T06:40:00',
                'value': 25
            },
            {
                'dateTime': '2024-10-14T06:50:00',
                'value': 25
            },
            {
                'dateTime': '2024-10-14T07:00:00',
                'value': 28
            },
            {
                'dateTime': '2024-10-14T07:10:00',
                'value': 29
            },
            {
                'dateTime': '2024-10-14T07:20:00',
                'value': 28
            },
            {
                'dateTime': '2024-10-14T07:30:00',
                'value': 26
            },
            {
                'dateTime': '2024-10-14T07:40:00',
                'value': 27
            },
            {
                'dateTime': '2024-10-14T07:50:00',
                'value': 27
            },
            {
                'dateTime': '2024-10-14T08:00:00',
                'value': 28
            },
            {
                'dateTime': '2024-10-14T08:10:00',
                'value': 26
            },
            {
                'dateTime': '2024-10-14T08:20:00',
                'value': 26
            },
            {
                'dateTime': '2024-10-14T08:30:00',
                'value': 26
            },
            {
                'dateTime': '2024-10-14T08:40:00',
                'value': 27
            },
            {
                'dateTime': '2024-10-14T08:50:00',
                'value': 28
            },
            {
                'dateTime': '2024-10-14T09:00:00',
                'value': 28
            },
            {
                'dateTime': '2024-10-14T09:10:00',
                'value': 28
            },
            {
                'dateTime': '2024-10-14T09:20:00',
                'value': 29
            },
            {
                'dateTime': '2024-10-14T09:30:00',
                'value': 29
            },
            {
                'dateTime': '2024-10-14T09:40:00',
                'value': 29
            },
            {
                'dateTime': '2024-10-14T09:50:00',
                'value': 29
            },
            {
                'dateTime': '2024-10-14T10:00:00',
                'value': 29
            },
            {
                'dateTime': '2024-10-14T10:10:00',
                'value': 30
            },
            {
                'dateTime': '2024-10-14T10:20:00',
                'value': 30
            },
            {
                'dateTime': '2024-10-14T10:30:00',
                'value': 29
            },
            {
                'dateTime': '2024-10-14T10:40:00',
                'value': 30
            },
            {
                'dateTime': '2024-10-14T10:50:00',
                'value': 30
            },
            {
                'dateTime': '2024-10-14T11:00:00',
                'value': 30
            },
            {
                'dateTime': '2024-10-14T11:10:00',
                'value': 30
            },
            {
                'dateTime': '2024-10-14T11:20:00',
                'value': 30
            },
            {
                'dateTime': '2024-10-14T11:30:00',
                'value': 31
            },
            {
                'dateTime': '2024-10-14T11:40:00',
                'value': 30
            },
            {
                'dateTime': '2024-10-14T11:50:00',
                'value': 31
            },
            {
                'dateTime': '2024-10-14T12:00:00',
                'value': 31
            },
            {
                'dateTime': '2024-10-14T12:10:00',
                'value': 31
            },
            {
                'dateTime': '2024-10-14T12:20:00',
                'value': 30
            },
            {
                'dateTime': '2024-10-14T12:30:00',
                'value': 30
            },
            {
                'dateTime': '2024-10-14T12:40:00',
                'value': 30
            },
            {
                'dateTime': '2024-10-14T12:50:00',
                'value': 31
            },
            {
                'dateTime': '2024-10-14T13:00:00',
                'value': 31
            },
            {
                'dateTime': '2024-10-14T13:10:00',
                'value': 31
            },
            {
                'dateTime': '2024-10-14T13:20:00',
                'value': 32
            },
            {
                'dateTime': '2024-10-14T13:30:00',
                'value': 32
            },
            {
                'dateTime': '2024-10-14T13:40:00',
                'value': 32
            },
            {
                'dateTime': '2024-10-14T13:50:00',
                'value': 32
            },
            {
                'dateTime': '2024-10-14T14:00:00',
                'value': 32
            },
            {
                'dateTime': '2024-10-14T14:10:00',
                'value': 33
            },
            {
                'dateTime': '2024-10-14T14:20:00',
                'value': 33
            },
            {
                'dateTime': '2024-10-14T14:30:00',
                'value': 34
            },
            {
                'dateTime': '2024-10-14T14:40:00',
                'value': 33
            },
            {
                'dateTime': '2024-10-14T14:50:00',
                'value': 34
            },
            {
                'dateTime': '2024-10-14T15:00:00',
                'value': 34
            },
            {
                'dateTime': '2024-10-14T15:10:00',
                'value': 34
            },
            {
                'dateTime': '2024-10-14T15:20:00',
                'value': 34
            },
            {
                'dateTime': '2024-10-14T15:30:00',
                'value': 36
            },
            {
                'dateTime': '2024-10-14T15:40:00',
                'value': 37
            },
            {
                'dateTime': '2024-10-14T15:50:00',
                'value': 36
            },
            {
                'dateTime': '2024-10-14T16:00:00',
                'value': 36
            }
        ]
    },
    {
        'deviceId': '70025e99-d62c-44f3-b85f-c54d77bd0b19',
        'varName': 'R2_STAND',
        'data': [
            {
                'dateTime': '2024-10-14T00:00:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T00:10:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T00:20:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T00:30:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T00:40:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T00:50:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T01:00:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T01:10:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T01:20:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T01:30:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T01:40:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T01:50:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T02:00:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T02:10:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T02:20:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T02:30:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T02:40:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T02:50:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T03:00:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T03:10:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T03:20:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T03:30:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T03:40:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T03:50:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T04:00:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T04:10:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T04:20:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T04:30:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T04:40:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T04:50:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T05:00:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T05:10:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T05:20:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T05:30:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T05:40:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T05:50:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T06:00:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T06:10:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T06:20:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T06:30:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T06:40:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T06:50:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T07:00:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T07:10:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T07:20:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T07:30:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T07:40:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T07:50:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T08:00:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T08:10:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T08:20:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T08:30:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T08:40:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T08:50:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T09:00:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T09:10:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T09:20:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T09:30:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T09:40:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T09:50:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T10:00:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T10:10:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T10:20:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T10:30:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T10:40:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T10:50:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T11:00:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T11:10:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T11:20:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T11:30:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T11:40:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T11:50:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T12:00:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T12:10:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T12:20:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T12:30:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T12:40:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T12:50:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T13:00:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T13:10:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T13:20:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T13:30:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T13:40:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T13:50:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T14:00:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T14:10:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T14:20:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T14:30:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T14:40:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T14:50:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T15:00:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T15:10:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T15:20:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T15:30:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T15:40:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T15:50:00',
                'value': 0
            },
            {
                'dateTime': '2024-10-14T16:00:00',
                'value': 0
            }
        ]
    },
    {
        'deviceId': '70025e99-d62c-44f3-b85f-c54d77bd0b19',
        'varName': 'R1_MIN',
        'data': [
            {
                'dateTime': '2024-10-14T00:00:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T00:10:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T00:20:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T00:30:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T00:40:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T00:50:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T01:00:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T01:10:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T01:20:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T01:30:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T01:40:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T01:50:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T02:00:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T02:10:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T02:20:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T02:30:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T02:40:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T02:50:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T03:00:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T03:10:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T03:20:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T03:30:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T03:40:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T03:50:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T04:00:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T04:10:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T04:20:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T04:30:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T04:40:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T04:50:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T05:00:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T05:10:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T05:20:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T05:30:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T05:40:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T05:50:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T06:00:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T06:10:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T06:20:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T06:30:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T06:40:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T06:50:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T07:00:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T07:10:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T07:20:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T07:30:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T07:40:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T07:50:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T08:00:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T08:10:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T08:20:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T08:30:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T08:40:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T08:50:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T09:00:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T09:10:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T09:20:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T09:30:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T09:40:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T09:50:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T10:00:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T10:10:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T10:20:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T10:30:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T10:40:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T10:50:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T11:00:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T11:10:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T11:20:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T11:30:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T11:40:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T11:50:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T12:00:00',
                'value': 9
            },
            {
                'dateTime': '2024-10-14T12:10:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T12:20:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T12:30:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T12:40:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T12:50:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T13:00:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T13:10:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T13:20:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T13:30:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T13:40:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T13:50:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T14:00:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T14:10:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T14:20:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T14:30:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T14:40:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T14:50:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T15:00:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T15:10:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T15:20:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T15:30:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T15:40:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T15:50:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T16:00:00',
                'value': 10
            }
        ]
    },
    {
        'deviceId': '70025e99-d62c-44f3-b85f-c54d77bd0b19',
        'varName': 'R1_MAX',
        'data': [
            {
                'dateTime': '2024-10-14T00:00:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T00:10:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T00:20:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T00:30:00',
                'value': 58
            },
            {
                'dateTime': '2024-10-14T00:40:00',
                'value': 59
            },
            {
                'dateTime': '2024-10-14T00:50:00',
                'value': 58
            },
            {
                'dateTime': '2024-10-14T01:00:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T01:10:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T01:20:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T01:30:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T01:40:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T01:50:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T02:00:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T02:10:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T02:20:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T02:30:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T02:40:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T02:50:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T03:00:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T03:10:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T03:20:00',
                'value': 58
            },
            {
                'dateTime': '2024-10-14T03:30:00',
                'value': 58
            },
            {
                'dateTime': '2024-10-14T03:40:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T03:50:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T04:00:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T04:10:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T04:20:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T04:30:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T04:40:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T04:50:00',
                'value': 58
            },
            {
                'dateTime': '2024-10-14T05:00:00',
                'value': 58
            },
            {
                'dateTime': '2024-10-14T05:10:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T05:20:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T05:30:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T05:40:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T05:50:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T06:00:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T06:10:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T06:20:00',
                'value': 58
            },
            {
                'dateTime': '2024-10-14T06:30:00',
                'value': 58
            },
            {
                'dateTime': '2024-10-14T06:40:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T06:50:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T07:00:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T07:10:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T07:20:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T07:30:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T07:40:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T07:50:00',
                'value': 59
            },
            {
                'dateTime': '2024-10-14T08:00:00',
                'value': 58
            },
            {
                'dateTime': '2024-10-14T08:10:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T08:20:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T08:30:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T08:40:00',
                'value': 58
            },
            {
                'dateTime': '2024-10-14T08:50:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T09:00:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T09:10:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T09:20:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T09:30:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T09:40:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T09:50:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T10:00:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T10:10:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T10:20:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T10:30:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T10:40:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T10:50:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T11:00:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T11:10:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T11:20:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T11:30:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T11:40:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T11:50:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T12:00:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T12:10:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T12:20:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T12:30:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T12:40:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T12:50:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T13:00:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T13:10:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T13:20:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T13:30:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T13:40:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T13:50:00',
                'value': 56
            },
            {
                'dateTime': '2024-10-14T14:00:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T14:10:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T14:20:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T14:30:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T14:40:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T14:50:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T15:00:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T15:10:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T15:20:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T15:30:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T15:40:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T15:50:00',
                'value': 57
            },
            {
                'dateTime': '2024-10-14T16:00:00',
                'value': 57
            }
        ]
    },
    {
        'deviceId': '70025e99-d62c-44f3-b85f-c54d77bd0b19',
        'varName': 'BUITEMP_KOPIEA',
        'data': [
            {
                'dateTime': '2024-10-14T00:00:00',
                'value': 4.4
            },
            {
                'dateTime': '2024-10-14T00:10:00',
                'value': 4.4
            },
            {
                'dateTime': '2024-10-14T00:20:00',
                'value': 4.1
            },
            {
                'dateTime': '2024-10-14T00:30:00',
                'value': 4.1
            },
            {
                'dateTime': '2024-10-14T00:40:00',
                'value': 4.2
            },
            {
                'dateTime': '2024-10-14T00:50:00',
                'value': 4.4
            },
            {
                'dateTime': '2024-10-14T01:00:00',
                'value': 4.6
            },
            {
                'dateTime': '2024-10-14T01:10:00',
                'value': 4.7
            },
            {
                'dateTime': '2024-10-14T01:20:00',
                'value': 4.8
            },
            {
                'dateTime': '2024-10-14T01:30:00',
                'value': 4.7
            },
            {
                'dateTime': '2024-10-14T01:40:00',
                'value': 4.6
            },
            {
                'dateTime': '2024-10-14T01:50:00',
                'value': 4.6
            },
            {
                'dateTime': '2024-10-14T02:00:00',
                'value': 4.6
            },
            {
                'dateTime': '2024-10-14T02:10:00',
                'value': 4.7
            },
            {
                'dateTime': '2024-10-14T02:20:00',
                'value': 4.7
            },
            {
                'dateTime': '2024-10-14T02:30:00',
                'value': 4.7
            },
            {
                'dateTime': '2024-10-14T02:40:00',
                'value': 4.6
            },
            {
                'dateTime': '2024-10-14T02:50:00',
                'value': 4.5
            },
            {
                'dateTime': '2024-10-14T03:00:00',
                'value': 4.4
            },
            {
                'dateTime': '2024-10-14T03:10:00',
                'value': 4.5
            },
            {
                'dateTime': '2024-10-14T03:20:00',
                'value': 4.3
            },
            {
                'dateTime': '2024-10-14T03:30:00',
                'value': 4.3
            },
            {
                'dateTime': '2024-10-14T03:40:00',
                'value': 4.4
            },
            {
                'dateTime': '2024-10-14T03:50:00',
                'value': 4.4
            },
            {
                'dateTime': '2024-10-14T04:00:00',
                'value': 4.4
            },
            {
                'dateTime': '2024-10-14T04:10:00',
                'value': 4.2
            },
            {
                'dateTime': '2024-10-14T04:20:00',
                'value': 4.1
            },
            {
                'dateTime': '2024-10-14T04:30:00',
                'value': 4.2
            },
            {
                'dateTime': '2024-10-14T04:40:00',
                'value': 4.2
            },
            {
                'dateTime': '2024-10-14T04:50:00',
                'value': 4.3
            },
            {
                'dateTime': '2024-10-14T05:00:00',
                'value': 4.3
            },
            {
                'dateTime': '2024-10-14T05:10:00',
                'value': 4.3
            },
            {
                'dateTime': '2024-10-14T05:20:00',
                'value': 4.3
            },
            {
                'dateTime': '2024-10-14T05:30:00',
                'value': 4.3
            },
            {
                'dateTime': '2024-10-14T05:40:00',
                'value': 4.3
            },
            {
                'dateTime': '2024-10-14T05:50:00',
                'value': 4.3
            },
            {
                'dateTime': '2024-10-14T06:00:00',
                'value': 4.2
            },
            {
                'dateTime': '2024-10-14T06:10:00',
                'value': 4.2
            },
            {
                'dateTime': '2024-10-14T06:20:00',
                'value': 4
            },
            {
                'dateTime': '2024-10-14T06:30:00',
                'value': 4.1
            },
            {
                'dateTime': '2024-10-14T06:40:00',
                'value': 4.2
            },
            {
                'dateTime': '2024-10-14T06:50:00',
                'value': 4.2
            },
            {
                'dateTime': '2024-10-14T07:00:00',
                'value': 4.3
            },
            {
                'dateTime': '2024-10-14T07:10:00',
                'value': 4.3
            },
            {
                'dateTime': '2024-10-14T07:20:00',
                'value': 4.1
            },
            {
                'dateTime': '2024-10-14T07:30:00',
                'value': 4
            },
            {
                'dateTime': '2024-10-14T07:40:00',
                'value': 4
            },
            {
                'dateTime': '2024-10-14T07:50:00',
                'value': 4.1
            },
            {
                'dateTime': '2024-10-14T08:00:00',
                'value': 4
            },
            {
                'dateTime': '2024-10-14T08:10:00',
                'value': 3.9
            },
            {
                'dateTime': '2024-10-14T08:20:00',
                'value': 3.9
            },
            {
                'dateTime': '2024-10-14T08:30:00',
                'value': 4.1
            },
            {
                'dateTime': '2024-10-14T08:40:00',
                'value': 4.2
            },
            {
                'dateTime': '2024-10-14T08:50:00',
                'value': 4.5
            },
            {
                'dateTime': '2024-10-14T09:00:00',
                'value': 4.6
            },
            {
                'dateTime': '2024-10-14T09:10:00',
                'value': 4.8
            },
            {
                'dateTime': '2024-10-14T09:20:00',
                'value': 4.9
            },
            {
                'dateTime': '2024-10-14T09:30:00',
                'value': 5
            },
            {
                'dateTime': '2024-10-14T09:40:00',
                'value': 5.1
            },
            {
                'dateTime': '2024-10-14T09:50:00',
                'value': 5.3
            },
            {
                'dateTime': '2024-10-14T10:00:00',
                'value': 5.4
            },
            {
                'dateTime': '2024-10-14T10:10:00',
                'value': 5.4
            },
            {
                'dateTime': '2024-10-14T10:20:00',
                'value': 5.5
            },
            {
                'dateTime': '2024-10-14T10:30:00',
                'value': 5.5
            },
            {
                'dateTime': '2024-10-14T10:40:00',
                'value': 5.4
            },
            {
                'dateTime': '2024-10-14T10:50:00',
                'value': 5.4
            },
            {
                'dateTime': '2024-10-14T11:00:00',
                'value': 5.5
            },
            {
                'dateTime': '2024-10-14T11:10:00',
                'value': 5.5
            },
            {
                'dateTime': '2024-10-14T11:20:00',
                'value': 5.6
            },
            {
                'dateTime': '2024-10-14T11:30:00',
                'value': 5.6
            },
            {
                'dateTime': '2024-10-14T11:40:00',
                'value': 5.7
            },
            {
                'dateTime': '2024-10-14T11:50:00',
                'value': 5.8
            },
            {
                'dateTime': '2024-10-14T12:00:00',
                'value': 6
            },
            {
                'dateTime': '2024-10-14T12:10:00',
                'value': 6.1
            },
            {
                'dateTime': '2024-10-14T12:20:00',
                'value': 6.3
            },
            {
                'dateTime': '2024-10-14T12:30:00',
                'value': 6.2
            },
            {
                'dateTime': '2024-10-14T12:40:00',
                'value': 6.2
            },
            {
                'dateTime': '2024-10-14T12:50:00',
                'value': 6.2
            },
            {
                'dateTime': '2024-10-14T13:00:00',
                'value': 6.3
            },
            {
                'dateTime': '2024-10-14T13:10:00',
                'value': 6.4
            },
            {
                'dateTime': '2024-10-14T13:20:00',
                'value': 6.4
            },
            {
                'dateTime': '2024-10-14T13:30:00',
                'value': 6.5
            },
            {
                'dateTime': '2024-10-14T13:40:00',
                'value': 6.7
            },
            {
                'dateTime': '2024-10-14T13:50:00',
                'value': 6.8
            },
            {
                'dateTime': '2024-10-14T14:00:00',
                'value': 7
            },
            {
                'dateTime': '2024-10-14T14:10:00',
                'value': 7.2
            },
            {
                'dateTime': '2024-10-14T14:20:00',
                'value': 7.4
            },
            {
                'dateTime': '2024-10-14T14:30:00',
                'value': 7.4
            },
            {
                'dateTime': '2024-10-14T14:40:00',
                'value': 7.3
            },
            {
                'dateTime': '2024-10-14T14:50:00',
                'value': 7.2
            },
            {
                'dateTime': '2024-10-14T15:00:00',
                'value': 7.2
            },
            {
                'dateTime': '2024-10-14T15:10:00',
                'value': 7.1
            },
            {
                'dateTime': '2024-10-14T15:20:00',
                'value': 7
            },
            {
                'dateTime': '2024-10-14T15:30:00',
                'value': 6.8
            },
            {
                'dateTime': '2024-10-14T15:40:00',
                'value': 6.7
            },
            {
                'dateTime': '2024-10-14T15:50:00',
                'value': 6.7
            },
            {
                'dateTime': '2024-10-14T16:00:00',
                'value': 6.9
            }
        ]
    },
    {
        'deviceId': '70025e99-d62c-44f3-b85f-c54d77bd0b19',
        'varName': 'AFD_INL_Tmom',
        'data': [
            {
                'dateTime': '2024-10-14T00:00:00',
                'value': 10.5
            },
            {
                'dateTime': '2024-10-14T00:10:00',
                'value': 10.7
            },
            {
                'dateTime': '2024-10-14T00:20:00',
                'value': 10.3
            },
            {
                'dateTime': '2024-10-14T00:30:00',
                'value': 11.5
            },
            {
                'dateTime': '2024-10-14T00:40:00',
                'value': 12
            },
            {
                'dateTime': '2024-10-14T00:50:00',
                'value': 11.4
            },
            {
                'dateTime': '2024-10-14T01:00:00',
                'value': 10.8
            },
            {
                'dateTime': '2024-10-14T01:10:00',
                'value': 10.4
            },
            {
                'dateTime': '2024-10-14T01:20:00',
                'value': 10.1
            },
            {
                'dateTime': '2024-10-14T01:30:00',
                'value': 10.7
            },
            {
                'dateTime': '2024-10-14T01:40:00',
                'value': 10.4
            },
            {
                'dateTime': '2024-10-14T01:50:00',
                'value': 10.1
            },
            {
                'dateTime': '2024-10-14T02:00:00',
                'value': 9.9
            },
            {
                'dateTime': '2024-10-14T02:10:00',
                'value': 10.7
            },
            {
                'dateTime': '2024-10-14T02:20:00',
                'value': 10.4
            },
            {
                'dateTime': '2024-10-14T02:30:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T02:40:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T02:50:00',
                'value': 10.5
            },
            {
                'dateTime': '2024-10-14T03:00:00',
                'value': 10.2
            },
            {
                'dateTime': '2024-10-14T03:10:00',
                'value': 9.9
            },
            {
                'dateTime': '2024-10-14T03:20:00',
                'value': 11.1
            },
            {
                'dateTime': '2024-10-14T03:30:00',
                'value': 11.5
            },
            {
                'dateTime': '2024-10-14T03:40:00',
                'value': 10.9
            },
            {
                'dateTime': '2024-10-14T03:50:00',
                'value': 10.4
            },
            {
                'dateTime': '2024-10-14T04:00:00',
                'value': 9.9
            },
            {
                'dateTime': '2024-10-14T04:10:00',
                'value': 10.8
            },
            {
                'dateTime': '2024-10-14T04:20:00',
                'value': 10.5
            },
            {
                'dateTime': '2024-10-14T04:30:00',
                'value': 10.2
            },
            {
                'dateTime': '2024-10-14T04:40:00',
                'value': 9.8
            },
            {
                'dateTime': '2024-10-14T04:50:00',
                'value': 11.3
            },
            {
                'dateTime': '2024-10-14T05:00:00',
                'value': 11.2
            },
            {
                'dateTime': '2024-10-14T05:10:00',
                'value': 10.7
            },
            {
                'dateTime': '2024-10-14T05:20:00',
                'value': 10.2
            },
            {
                'dateTime': '2024-10-14T05:30:00',
                'value': 9.8
            },
            {
                'dateTime': '2024-10-14T05:40:00',
                'value': 10.5
            },
            {
                'dateTime': '2024-10-14T05:50:00',
                'value': 10.6
            },
            {
                'dateTime': '2024-10-14T06:00:00',
                'value': 10.4
            },
            {
                'dateTime': '2024-10-14T06:10:00',
                'value': 9.9
            },
            {
                'dateTime': '2024-10-14T06:20:00',
                'value': 10.9
            },
            {
                'dateTime': '2024-10-14T06:30:00',
                'value': 11.2
            },
            {
                'dateTime': '2024-10-14T06:40:00',
                'value': 10.6
            },
            {
                'dateTime': '2024-10-14T06:50:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T07:00:00',
                'value': 9.7
            },
            {
                'dateTime': '2024-10-14T07:10:00',
                'value': 10.4
            },
            {
                'dateTime': '2024-10-14T07:20:00',
                'value': 10.1
            },
            {
                'dateTime': '2024-10-14T07:30:00',
                'value': 9.6
            },
            {
                'dateTime': '2024-10-14T07:40:00',
                'value': 10.6
            },
            {
                'dateTime': '2024-10-14T07:50:00',
                'value': 11.4
            },
            {
                'dateTime': '2024-10-14T08:00:00',
                'value': 10.8
            },
            {
                'dateTime': '2024-10-14T08:10:00',
                'value': 10.1
            },
            {
                'dateTime': '2024-10-14T08:20:00',
                'value': 9.7
            },
            {
                'dateTime': '2024-10-14T08:30:00',
                'value': 9.9
            },
            {
                'dateTime': '2024-10-14T08:40:00',
                'value': 11.2
            },
            {
                'dateTime': '2024-10-14T08:50:00',
                'value': 10.7
            },
            {
                'dateTime': '2024-10-14T09:00:00',
                'value': 10.2
            },
            {
                'dateTime': '2024-10-14T09:10:00',
                'value': 9.8
            },
            {
                'dateTime': '2024-10-14T09:20:00',
                'value': 9.6
            },
            {
                'dateTime': '2024-10-14T09:30:00',
                'value': 9.5
            },
            {
                'dateTime': '2024-10-14T09:40:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T09:50:00',
                'value': 9.8
            },
            {
                'dateTime': '2024-10-14T10:00:00',
                'value': 9.5
            },
            {
                'dateTime': '2024-10-14T10:10:00',
                'value': 9.6
            },
            {
                'dateTime': '2024-10-14T10:20:00',
                'value': 9.8
            },
            {
                'dateTime': '2024-10-14T10:30:00',
                'value': 9.6
            },
            {
                'dateTime': '2024-10-14T10:40:00',
                'value': 9.4
            },
            {
                'dateTime': '2024-10-14T10:50:00',
                'value': 9.8
            },
            {
                'dateTime': '2024-10-14T11:00:00',
                'value': 9.7
            },
            {
                'dateTime': '2024-10-14T11:10:00',
                'value': 9.5
            },
            {
                'dateTime': '2024-10-14T11:20:00',
                'value': 9.4
            },
            {
                'dateTime': '2024-10-14T11:30:00',
                'value': 9.6
            },
            {
                'dateTime': '2024-10-14T11:40:00',
                'value': 9.4
            },
            {
                'dateTime': '2024-10-14T11:50:00',
                'value': 9.4
            },
            {
                'dateTime': '2024-10-14T12:00:00',
                'value': 9.6
            },
            {
                'dateTime': '2024-10-14T12:10:00',
                'value': 9.6
            },
            {
                'dateTime': '2024-10-14T12:20:00',
                'value': 9.6
            },
            {
                'dateTime': '2024-10-14T12:30:00',
                'value': 9.5
            },
            {
                'dateTime': '2024-10-14T12:40:00',
                'value': 9.5
            },
            {
                'dateTime': '2024-10-14T12:50:00',
                'value': 9.6
            },
            {
                'dateTime': '2024-10-14T13:00:00',
                'value': 9.6
            },
            {
                'dateTime': '2024-10-14T13:10:00',
                'value': 9.5
            },
            {
                'dateTime': '2024-10-14T13:20:00',
                'value': 9.6
            },
            {
                'dateTime': '2024-10-14T13:30:00',
                'value': 9.7
            },
            {
                'dateTime': '2024-10-14T13:40:00',
                'value': 9.8
            },
            {
                'dateTime': '2024-10-14T13:50:00',
                'value': 9.8
            },
            {
                'dateTime': '2024-10-14T14:00:00',
                'value': 9.9
            },
            {
                'dateTime': '2024-10-14T14:10:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T14:20:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T14:30:00',
                'value': 10.1
            },
            {
                'dateTime': '2024-10-14T14:40:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T14:50:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T15:00:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T15:10:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T15:20:00',
                'value': 10
            },
            {
                'dateTime': '2024-10-14T15:30:00',
                'value': 9.9
            },
            {
                'dateTime': '2024-10-14T15:40:00',
                'value': 9.8
            },
            {
                'dateTime': '2024-10-14T15:50:00',
                'value': 9.9
            },
            {
                'dateTime': '2024-10-14T16:00:00',
                'value': 10
            }
        ]
    }
];
