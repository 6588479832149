export const promoMenu = [
    {
        id: 'f282b1a4-e49a-44bc-86ab-075911b6666e',
        name: 'Field 1',
        subMenu: [
            {
                id: '044a5aee-2dec-49e5-9ecc-7316cf4f8090',
                name: 'House 1',
                usedLicenceKey: '62-33-71-42-4B-D6',
                online: true,
                subMenu: [
                    {
                        id: '054a51ee-2dec-49e5-9ecc-7316cf4f8090',
                        name: 'PL-9600',
                        hardware: 269,
                        version: 204,
                        address: 2,
                        online: true,
                        isLicenced: true
                    }
                ]
            },
            {
                id: '054a1aee-2dec-49e5-9ecc-7316cf4f8090',
                name: 'House 2'
            },
            {
                id: '054a5aee-2dec-41e5-9ecc-7316cf4f8090',
                name: 'House 3'
            },
            {
                id: '054a5aee-2dec-49e5-9ecc-7316cf4f8090',
                name: 'House 4'
            }
        ]
    },
    {
        id: 'f381b1a4-e49a-44bc-86ab-075911b6666e',
        name: 'Field 2',
        subMenu: [
            {
                id: '044a5aee-2dec-49e5-9ecc-7316cf4f1090',
                name: 'House 1'
            },
            {
                id: '054a1aee-2dec-49e5-9ecc-7316cf4f8090',
                name: 'House 2'
            }
        ]
    },
    {
        id: 'f311b1a4-e49a-44bc-86ab-075911b6666e',
        name: 'Field 3',
        subMenu: [
            {
                id: '044a1aee-2dec-49e5-9ecc-7316cf4f8090',
                name: 'House 1'
            },
            {
                id: '054a1aee-2dec-49e5-1ecc-7316cf4f8090',
                name: 'House 2'
            },
            {
                id: '044a1aee-2dec-49e5-9ecc-7116cf4f8090',
                name: 'House 3'
            },
            {
                id: '054a1aee-2dec-49e5-11cc-7316cf4f8090',
                name: 'House 4'
            },
            {
                id: '044a1aee-2dec-49e5-9ecc-7316cf4f1090',
                name: 'House 5'
            }
        ]
    }
];
