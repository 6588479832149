import Cookies from 'universal-cookie';
export const textToCamelCase = (str) => {
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index == 0 ? word.toLowerCase() : word.toUpperCase();
    })
        .replace(/\s+/g, '');
};
export const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};
export const getThemeClassName = () => {
    const cookies = new Cookies();
    return `${cookies.get('theme')}-theme`;
};
export const numberOfTrueValues = (obj, name) => {
    let counter = 0;
    for (let index = 0; index < obj.length; index++) {
        if (obj[index][name] === true) {
            counter++;
        }
    }
    return counter;
};
export const isObjEmpty = (obj) => {
    return Object.keys(obj).length === 0;
};
