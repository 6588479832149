import StienenTable from '@elementComponents/Table';
import { Button } from '@mui/material';
import { getDevicesByLocationId } from 'app/locations/state/api';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const WizardStepDevices = (props) => {
    const { t } = useTranslation();
    const [, setTime] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const [devices, setDevices] = useState([]);
    const { onClickLogout } = props;
    const auth = useSelector((state) => state.auth && state.auth);
    const locations = useSelector((state) => state.locations && state.locations.list && state.locations.list.data);
    const locationUuid = locations[0] && locations[0].id;
    console.log('locationUuid=', locationUuid);
    const refreshDevices = async () => {
        console.log('REFRESH DEVICES');
        try {
            const response = await getDevicesByLocationId(locationUuid);
            if (response && response.success === true) {
                console.log('response=', response);
                setLoading(false);
                setDevices([]);
            }
        }
        catch (e) {
            console.error(e);
        }
    };
    useEffect(() => {
        const interval = auth && setInterval(() => {
            console.log('REFRESH EVERY 5 SECONDS');
            setTime(new Date());
            auth && auth.success && refreshDevices();
        }, 1000 * 5);
        return () => clearInterval(interval);
    }, []);
    const onClickComplete = async () => {
        console.log('onClickComplete');
    };
    const headers = [
        {
            id: 'name',
            numeric: false,
            label: t('devices.name'),
        },
        {
            id: 'hardware',
            numeric: false,
            label: t('devices.hardware'),
        },
    ];
    const devicesData = devices && devices.length > 0 ? devices.map((device) => {
        return {
            name: device.name,
            hardware: device.hardware
        };
    }) : [];
    return (React.createElement(React.Fragment, null,
        React.createElement("p", null, t('auth.licenseStepDevicesText')),
        React.createElement("div", { className: 'wizard-devices-block' },
            React.createElement(StienenTable, { isLoading: loading, numberOfRows: 10, headers: headers, rows: devicesData && devicesData, defaultOrder: 'name', title: t('locations.devices') })),
        React.createElement(Form, { onSubmit: () => { }, render: () => {
                return (React.createElement("form", { className: 'mt2' },
                    React.createElement("div", { className: 'modal-action-buttons' },
                        React.createElement(Button, { className: 'text-btn', onClick: () => onClickLogout() }, t('main.logout')),
                        React.createElement(Button, { variant: 'contained', className: 'float-right', onClick: () => onClickComplete() }, t('auth.complete')))));
            } })));
};
export default WizardStepDevices;
