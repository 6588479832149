import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import SortableItem from './TabWidgets/SortableItem';
import { Button } from '@mui/material';
const TabWidgets = (props) => {
    const { t } = useTranslation();
    const { setVisible, selectedContent, setTabCurrentContent } = props;
    const widgets = useSelector((state) => state && state.widgets && state.widgets.widgets);
    const onCloseSideBar = () => {
        setVisible(false);
        setTabCurrentContent(selectedContent.content);
    };
    const onSaveEdit = () => {
        setVisible(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("b", { className: 'label' }, t('widgets.addWidget')),
        React.createElement("div", { className: 'add-widget-container' },
            React.createElement(Grid, null,
                React.createElement(Grid.Row, { columns: 1 }, widgets && widgets.length > 0 && widgets.map((item, key) => {
                    return (React.createElement(Grid.Column, { key: key },
                        React.createElement(SortableItem, { handle: true, content: item })));
                })))),
        React.createElement(Button, { variant: 'contained', className: 'float-right ml1', onClick: () => onSaveEdit() }, t('main.save')),
        React.createElement(Button, { className: 'text-btn float-right', onClick: () => onCloseSideBar() }, t('main.cancel'))));
};
export default TabWidgets;
