import i18n from '../i18n';
export const validateWidgetsNewTab = (values) => {
    const errors = {};
    if (!values.NewTabName) {
        errors.NewTabName = i18n.t('validations.required');
    }
    else if (values.NewTabName.length < 3) {
        errors.NewTabName = i18n.t('validations.theMinimumLengthofaNameMustBe3Characters');
    }
    return errors;
};
export const validateWidgetsTab = (values) => {
    const errors = {};
    if (!values.TabName) {
        errors.TabName = i18n.t('validations.required');
    }
    else if (values.TabName.length < 3) {
        errors.TabName = i18n.t('validations.theMinimumLengthofaNameMustBe3Characters');
    }
    return errors;
};
