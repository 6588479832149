const getLastActiveItem = (example) => {
    const activeItem = document.getElementById(`device-nav-example-${example}`)?.querySelectorAll('li.last-active')[0];
    activeItem && activeItem.classList.remove('last-active');
    const activeItems = document.getElementById(`device-nav-example-${example}`)?.querySelectorAll('li.active');
    const latestActiveItem = activeItems && activeItems[activeItems.length - 1];
    latestActiveItem && latestActiveItem.classList.add('last-active');
};
export const onClickItem = (id, example) => {
    const navElement = document.getElementById(`device-nav-example-${example}`)?.getElementsByClassName(`example-${example}`)[0];
    const listElement = document.getElementById(`device-nav-example-${example}`)?.getElementsByClassName(`device-menu-${id}`)[0];
    if (listElement && listElement.classList && listElement.classList.contains('active')) {
        listElement.classList.remove('active');
    }
    else {
        const elementItems = navElement && navElement.querySelectorAll('li.active');
        elementItems && elementItems.forEach((item) => {
            item.classList.remove('active');
        });
        listElement && listElement.classList && listElement.classList.add('active');
    }
    getLastActiveItem(example);
};
export const onClickSubItem = (navId, id, example) => {
    const subNavElement = document.getElementById(`sub-nav-${navId}`);
    const subListElement = document.getElementById(`device-nav-example-${example}`)?.getElementsByClassName(`device-menu-${id}`)[0];
    if (subListElement && subListElement.classList && subListElement.classList.contains('active')) {
        subListElement.classList.remove('active');
    }
    else {
        const subElementItems = subNavElement && subNavElement.querySelectorAll('li.active');
        subElementItems && subElementItems.forEach((item) => {
            item.classList.remove('active');
        });
        subListElement && subListElement.classList && subListElement.classList.add('active');
    }
    getLastActiveItem(example);
};
