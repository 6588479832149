import api from '@state/api';
const endPoint = `${process.env.REF_API}/refapi/v1`;
export const getAlarmReference = async (hardware, version, language) => {
    const { data } = await api.get(`${endPoint}/translation/${hardware}/${version}/alarm?language=${language}`);
    return {
        hardware: hardware,
        version: version,
        language: language,
        data
    };
};
export const getControlReference = async (hardware, version, language) => {
    const { data } = await api.get(`${endPoint}/translation/${hardware}/${version}/control?language=${language}`);
    return {
        hardware: hardware,
        version: version,
        language: language,
        data
    };
};
export const getFullReference = async (hardware, version) => {
    const { data } = await api.get(`${endPoint}/reference/${hardware}/${version}`);
    return {
        hardware: hardware,
        version: version,
        data
    };
};
export const getNamedReference = async (hardware, version, language) => {
    const { data } = await api.get(`${endPoint}/translation/${hardware}/${version}/named?language=${language}`);
    return {
        hardware: hardware,
        version: version,
        language: language,
        data
    };
};
