import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import IconChevron from '!svg-react-loader?name=IconChevron!assets/svg/icon-chevron-left.svg';
import { orderBy } from 'lodash/fp';
import { Link } from 'react-router-dom';
import { onClickItem, onClickSubItem } from '@utils/menu';
import { fetchLocations } from 'app/locations/state/slice';
const LocationsSubMenu = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const customers = useSelector((state) => state.customers && state.customers);
    const auth = useSelector((state) => state.auth && state.auth);
    const locations = useSelector((state) => state.locations && state.locations.list && state.locations.list.data);
    const userId = auth && auth.user && auth.user.userId;
    const locationsSorted = orderBy([(gateway) => gateway.name.toLowerCase()], 'asc', locations);
    const customerId = customers && customers.current && customers.current.id;
    useEffect(() => {
        const pagePath = location.pathname;
        const pathArray = pagePath.split('/');
        const pathArrayWithoutEmpty = pathArray.filter((elm) => elm);
        if (pathArrayWithoutEmpty[0] === 'locations' && pathArrayWithoutEmpty[1]) {
            const locationId = pathArrayWithoutEmpty[1];
            onClickItem(locationId, 1);
            if (pathArrayWithoutEmpty[2]) {
                const deviceId = pathArrayWithoutEmpty[2];
                onClickSubItem(locationId, deviceId, 1);
            }
        }
    }, [history]);
    useEffect(() => {
        auth && auth.success && userId && dispatch(fetchLocations(customerId));
    }, [userId, auth]);
    return (React.createElement("div", { className: 'sidebar-menu' },
        React.createElement("div", { className: 'device-menu sub-menu', id: 'device-nav-example-1' },
            React.createElement("div", { className: 'sub-menu-header' }, t('main.locations')),
            React.createElement("div", { className: 'menu-with-submenu' },
                React.createElement("ul", { id: "device-nav", className: 'example-1 content-level-1' }, locationsSorted &&
                    locationsSorted.map((item, key) => {
                        const devicesSorted = orderBy([(device) => device.name.toLowerCase()], 'asc', item.devices);
                        const numberOfDevices = item.devices.length;
                        return (React.createElement("li", { key: key, id: `device-menu-${item.id}`, className: `device-menu-${item.id} level-1` },
                            React.createElement(Link, { to: `/locations/${item.id}`, onClick: () => onClickItem(item.id, 1), className: 'parent-item' },
                                React.createElement("p", null, item.name),
                                numberOfDevices > 0 &&
                                    React.createElement("div", { className: 'chevron parent-chevron' },
                                        React.createElement(IconChevron, null))),
                            React.createElement("ul", { id: `sub-nav-${item.id}`, className: `sub-nav-${item.id} content-level-2` }, numberOfDevices > 0 && devicesSorted.map((item2, key2) => {
                                return (React.createElement("li", { key: key2, id: `device-menu-${item2.id}`, className: `device-menu-${item2.id} level-2` },
                                    React.createElement(Link, { to: `/locations/${item2.gatewayId}/${item2.id}`, className: 'sub-item level-2', onClick: () => onClickSubItem(item.id, item2.id, 1) },
                                        React.createElement("p", null, item2.name))));
                            }))));
                    }))))));
};
export default LocationsSubMenu;
