import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchDeviceBackups } from 'app/device/state/slice';
import CreateBackup from './CreateBackup';
import StienenTable from '@elementComponents/Table';
const Backups = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { deviceId } = useParams();
    const deviceBackups = useSelector((state) => state.device && state.device.backups && state.device.backups.data);
    const deviceBackupsisLoaded = useSelector((state) => state.device && state.device.backups && state.device.backups.isLoaded);
    useEffect(() => {
        deviceId && dispatch(fetchDeviceBackups(deviceId));
    }, [deviceId]);
    const backupData = deviceBackups && deviceBackups.map((backup) => {
        return {
            uuid: backup.id,
            date: backup.date
        };
    });
    const headers = [
        {
            id: 'date',
            numeric: false,
            label: t('locations.date'),
        }
    ];
    const isLoading = deviceBackupsisLoaded && (deviceBackups && deviceBackups.length > 0) && (backupData && backupData.length > 0)
        ? false
        : deviceBackupsisLoaded && (deviceBackups && deviceBackups.length === 0)
            ? false
            : true;
    return (React.createElement("div", null,
        React.createElement("div", { className: 'content-title justify-content-space-between' },
            React.createElement("div", { className: 'title' },
                React.createElement("h2", null, t('locations.backups'))),
            React.createElement("div", { className: 'justify-content-end align-items-center' },
                React.createElement(CreateBackup, { deviceId: deviceId }))),
        React.createElement(StienenTable, { searchBar: true, showCheckboxes: true, isLoading: isLoading, numberOfRows: 25, headers: headers, rows: backupData.length > 0 ? backupData : [], defaultOrder: 'date', title: t('locations.backups') })));
};
export default Backups;
