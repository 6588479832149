import i18n from '../i18n';
export const validateAnalyticsNewColumn = (values) => {
    const errors = {};
    if (!values.Device) {
        errors.Device = i18n.t('validations.required');
    }
    if (!values.Column) {
        errors.Column = i18n.t('validations.required');
    }
    return errors;
};
