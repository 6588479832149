import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { DateRangePicker } from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';
import { CustomProvider } from 'rsuite';
import IconCalendar from '!svg-react-loader?name=IconCalendar!assets/svg/icon-calendar.svg';
import { getCookieLocale } from '@utils/locales';
import nlNL from 'rsuite/locales/nl_NL';
import enGB from 'rsuite/locales/en_GB';
import deDE from 'rsuite/locales/de_DE';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import startOfMonth from 'date-fns/startOfMonth';
import addMonths from 'date-fns/addMonths';
import endOfMonth from 'date-fns/endOfMonth';
import { useTranslation } from 'react-i18next';
import startOfWeek from 'date-fns/startOfWeek';
import { isDate } from 'date-fns/esm';
export const DateRangePickerComponent = (props) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState(false);
    const [value, setValue] = useState();
    const [open, setOpen] = useState(false);
    const { afterToday } = DateRangePicker;
    const { name, className, label, onChangeDate, cleanable, defaultValue } = props;
    const calendarLocale = getCookieLocale().code === 'nl' ? nlNL
        : getCookieLocale().code === 'en' ? enGB
            : getCookieLocale().code === 'de' ? deDE
                : enGB;
    const onChangeValue = (date) => {
        date.length > 0 ? setSelected(true) : setSelected(false);
        onChangeDate(date);
        setValue(date);
        setOpen(false);
    };
    const predefinedRanges = [
        {
            label: t('calendar.today'),
            value: [new Date(), new Date()]
        },
        {
            label: t('calendar.yesterday'),
            value: [addDays(new Date(), -1), addDays(new Date(), -1)]
        },
        {
            label: t('calendar.thisWeek'),
            value: [startOfWeek(new Date(), { weekStartsOn: 1 }), new Date()]
        },
        {
            label: t('calendar.last7Days'),
            value: [subDays(new Date(), 6), new Date()]
        },
        {
            label: t('calendar.last30Days'),
            value: [subDays(new Date(), 29), new Date()]
        },
        {
            label: t('calendar.thisMonth'),
            value: [startOfMonth(new Date()), new Date()]
        },
        {
            label: t('calendar.previousMonth'),
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))]
        }
    ];
    const hasDefaultValue = defaultValue && isDate(defaultValue[0]);
    useEffect(() => {
        hasDefaultValue === true && setValue(defaultValue);
    }, [hasDefaultValue]);
    return (React.createElement("div", { className: `MuiCustomDatePicker-root ${className} ${open && 'open'} ${(hasDefaultValue || selected) && 'selected'} ` },
        React.createElement(Field, { name: name ? name : '', component: () => {
                return (React.createElement(CustomProvider, { locale: calendarLocale },
                    label &&
                        React.createElement("label", null,
                            React.createElement("span", null, label),
                            React.createElement("legend", null)),
                    open && React.createElement("div", { className: 'background' }),
                    React.createElement(DateRangePicker, { calendarSnapping: true, defaultValue: hasDefaultValue ? defaultValue : undefined, ranges: predefinedRanges, showHeader: false, shouldDisableDate: afterToday(), cleanable: cleanable, appearance: 'default', placeholder: ' ', caretAs: IconCalendar, open: open, value: value, weekStart: 1, format: 'dd LLL yyyy', character: ' - ', onOpen: () => setOpen(true), onClose: () => setOpen(false), onChange: (date) => onChangeValue(date), onOk: (date) => onChangeValue(date) })));
            } })));
};
