import { InputAdornment } from '@mui/material';
import React from 'react';
import { showErrorOnBlur, TextField } from 'mui-rff';
import { Field } from 'react-final-form';
export const InputComponent = (props) => {
    const { name, label, type, startAdornment, endAdornment, required, fullWidth, disabled, placeholder, className } = props;
    return (React.createElement("div", { className: className },
        React.createElement(Field, { name: name ? name : '' }, ({ input }) => {
            return (React.createElement(TextField, { ...input, fullWidth: fullWidth, type: type ? type : 'text', slotProps: {
                    input: {
                        startAdornment: startAdornment && React.createElement(InputAdornment, { position: "start" }, startAdornment),
                        endAdornment: endAdornment && React.createElement(InputAdornment, { position: "end" }, endAdornment)
                    }
                }, name: name, disabled: disabled, required: required, placeholder: placeholder, label: label ? label : false, showError: showErrorOnBlur }));
        })));
};
