import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { addDeviceToVisitedMenu, getDeviceById } from '@utils/devices';
import { useTranslation } from 'react-i18next';
import { StienenMessage } from '@elementComponents/Messages';
import { fetchDeviceMenu } from 'app/device/state/slice';
import { useDispatch, useSelector } from 'react-redux';
import IconEye from '!svg-react-loader?name=IconEye!assets/svg/icon-eye.svg';
import IconDevice from '!svg-react-loader?name=IconDevice!assets/svg/icon-info-circle.svg';
import IconCloudOnline from '!svg-react-loader?name=IconCloudOnline!assets/svg/icon-cloud-online.svg';
import IconStatistics from '!svg-react-loader?name=IconStatistics!assets/svg/icon-statistics.svg';
import IconBook from '!svg-react-loader?name=IconBook!assets/svg/icon-book.svg';
import TabRemoteFlutter from './components/TabRemoteFlutter';
import TabLogs from './components/TabLogs';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
const Device = () => {
    const { t } = useTranslation();
    const { locationId, deviceId, tabType } = useParams();
    const locations = useSelector((state) => state.locations && state.locations.list && state.locations.list.data);
    const customers = useSelector((state) => state.customers && state.customers);
    const customerId = customers && customers.current && customers.current.id;
    const device = getDeviceById(deviceId, locations);
    const dispatch = useDispatch();
    const [value, setValue] = useState('1');
    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        device && device.hardware && device.version && deviceId && dispatch(fetchDeviceMenu(device.hardware, device.version, deviceId));
    }, [deviceId]);
    useEffect(() => {
        tabType === 'remote' ? setValue('0')
            : tabType === 'device' ? setValue('1')
                : tabType === 'status' ? setValue('2')
                    : tabType === 'statistics' ? setValue('3')
                        : tabType === 'logs' ? setValue('4')
                            : !tabType && setValue('0');
    }, [tabType, deviceId]);
    const tabs = [
        {
            label: t('devices.remote'),
            icon: React.createElement(IconEye, null),
            link: `/locations/${locationId}/${deviceId}/remote`,
            content: React.createElement(TabRemoteFlutter, { device: device })
        },
        {
            label: t('devices.device'),
            icon: React.createElement(IconDevice, null),
            link: `/locations/${locationId}/${deviceId}/device`,
            content: React.createElement("div", null, "Tab Device Content")
        },
        {
            label: t('devices.status'),
            icon: React.createElement(IconCloudOnline, null),
            link: `/locations/${locationId}/${deviceId}/status`,
            content: React.createElement("div", null, "Tab status Content")
        },
        {
            label: t('devices.statistics'),
            icon: React.createElement(IconStatistics, null),
            link: `/locations/${locationId}/${deviceId}/statistics`,
            content: React.createElement("div", null, "Tab statistics Content")
        },
        {
            label: t('devices.logs'),
            icon: React.createElement(IconBook, null),
            link: `/locations/${locationId}/${deviceId}/logs`,
            content: React.createElement(TabLogs, { device: device })
        }
    ];
    useEffect(() => {
        console.log('device=', device);
        device && addDeviceToVisitedMenu(device, customerId);
    }, [device]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'content-title justify-content-space-between' },
            React.createElement("div", { className: 'title' },
                React.createElement("h2", null, device && device.name))),
        device && device.isLicenced === false &&
            React.createElement(StienenMessage, { type: 'warning', header: t('main.warning'), description: t('devices.notLicensedMessageDescription'), className: 'mb1' }),
        device && !device.online &&
            React.createElement(StienenMessage, { type: 'error', header: t('main.error'), description: t('devices.deviceIsOfflineText'), className: 'mb1' }),
        React.createElement(Box, { className: 'stienen-tabs mb2' },
            React.createElement(TabContext, { value: value },
                React.createElement("div", { className: 'header-border' }),
                React.createElement(TabList, { variant: 'scrollable', allowScrollButtonsMobile: true, onChange: handleChange }, tabs && tabs.map((tab, key) => {
                    return (React.createElement(Tab, { key: key, label: tab.label, icon: tab.icon, iconPosition: 'start', value: `${key}`, component: Link, to: tab.link }));
                })),
                tabs && tabs.map((tab, key) => {
                    return (React.createElement(TabPanel, { key: key, value: `${key}` }, tab.content));
                })))));
};
export default Device;
