import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'semantic-ui-react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import TabsList from './TabTabs/TabsList';
import TabsListItem from './TabTabs/TabsListItem';
import uuid from 'react-uuid';
import slugify from 'slugify';
import { storeTabsSuccess } from 'app/widgets/state/slice';
import { Button, Tooltip } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { InputComponent } from '@formComponents/Input';
import { Form } from 'react-final-form';
import { validateWidgetsNewTab } from '@validations/widgets';
const TabTabs = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { visible, setVisible } = props;
    const tabs = useSelector((state) => state && state.widgets && state.widgets.tabs);
    const [tabItems, setTabItems] = useState([]);
    useEffect(() => {
        tabs && tabs.length > 0 && setTabItems(tabs);
    }, [tabs, visible]);
    const onCloseSideBar = () => {
        setVisible(false);
    };
    const onAddNew = (values, form) => {
        const newTab = {
            index: tabItems.length,
            uuid: uuid(),
            name: values && values.NewTabName,
            friendlyName: slugify(values && values.NewTabName, { lower: true })
        };
        const newArray = tabItems.concat(newTab);
        setTabItems(newArray);
        form.restart();
    };
    const onSaveEdit = () => {
        const newArray = tabItems.map((obj, key) => {
            return {
                index: key,
                uuid: obj.uuid,
                color: obj.color ? obj.color : '008072',
                name: obj.name,
                friendlyName: slugify(obj.name, { lower: true })
            };
        });
        dispatch(storeTabsSuccess(newArray));
        setVisible(false);
    };
    const onDeleteTab = (uuid) => {
        const newArray = tabItems.filter((obj) => {
            return obj.uuid !== uuid;
        });
        setTabItems(newArray);
    };
    const dragEnded = (param) => {
        const { source, destination } = param;
        const _arr = [...tabItems];
        const _item = _arr.splice(source.index, 1)[0];
        _arr.splice(destination.index, 0, _item);
        setTabItems(_arr);
    };
    const addIconBtn = (values, invalid, form) => {
        return (React.createElement(Tooltip, { arrow: true, placement: 'top', title: t('main.add') },
            React.createElement(Button, { variant: 'contained', disabled: (invalid || !values || !values.NewTabName), onClick: () => onAddNew(values, form), className: 'action-btn' },
                React.createElement(AddOutlinedIcon, null))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("b", { className: 'label' }, t('widgets.newTab')),
        React.createElement(Form, { enableReinitialize: true, onSubmit: () => { }, validate: validateWidgetsNewTab, render: ({ invalid, values, form }) => (React.createElement("form", null,
                React.createElement(InputComponent, { name: 'NewTabName', label: t('widgets.name'), endAdornment: addIconBtn(values, invalid, form), className: 'input-with-action-btn mt-half' }))) }),
        React.createElement(Divider, { section: true }),
        React.createElement("b", { className: 'label' }, t('main.order')),
        React.createElement(DragDropContext, { onDragEnd: dragEnded },
            React.createElement(Droppable, { droppableId: 'tabItems-wrapper' }, (provided) => (React.createElement(TabsList, { ref: provided.innerRef, ...provided.droppableProps },
                tabItems && tabItems.length > 0 && tabItems.map((item, index) => {
                    return (React.createElement(Draggable, { draggableId: `item-${item.uuid}`, index: index, key: item.uuid }, (_provided, _snapshot) => (React.createElement(TabsListItem, { showColor: true, showInput: true, showDelete: true, ref: _provided.innerRef, dragHandleProps: _provided.dragHandleProps, ..._provided.draggableProps, snapshot: _snapshot, item: item, onDeleteTab: onDeleteTab, tabItems: tabItems, setTabItems: setTabItems }))));
                }),
                provided.placeholder)))),
        React.createElement(Button, { variant: 'contained', className: 'float-right ml1', onClick: () => onSaveEdit() }, t('main.save')),
        React.createElement(Button, { className: 'text-btn float-right', onClick: () => onCloseSideBar() }, t('main.cancel'))));
};
export default TabTabs;
