import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid, GridColumn, GridRow } from 'semantic-ui-react';
import IconUser from '!svg-react-loader?name=IconUser!assets/svg/icon-user.svg';
import IconDealer from '!svg-react-loader?name=IconDealer!assets/svg/icon-dealer.svg';
import IconEuro from '!svg-react-loader?name=IconEuro!assets/svg/icon-euro.svg';
import IconLocations from '!svg-react-loader?name=IconLocations!assets/svg/icon-location.svg';
import { getNumberOfDevices } from '@utils/customer';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
const AdminRedirect = () => {
    const { t } = useTranslation();
    const numberOfDevices = getNumberOfDevices();
    const auth = useSelector((state) => state.auth && state.auth);
    const userInvites = useSelector((state) => state && state.customers && state.customers.current && state.customers.current.userInvites);
    const dealerInvites = useSelector((state) => state && state.dealers && state.dealers.invites && state.dealers.invites.data);
    const isCustomerAccount = auth && auth.user && auth.user.isCustomerAccount;
    const items = [
        {
            text: t('main.users'),
            subText: `${userInvites && userInvites.length} ${t('main.users')}`,
            link: '/admin/users',
            icon: React.createElement(IconUser, null),
        },
        {
            text: t('main.dealers'),
            subText: `${dealerInvites && dealerInvites.length} ${t('main.dealers')}`,
            link: '/admin/dealers',
            icon: React.createElement(IconDealer, null),
        },
        {
            text: t('main.subscription'),
            subText: `${numberOfDevices && numberOfDevices} ${t('users.devices')}`,
            link: '/admin/subscription',
            icon: React.createElement(IconEuro, null),
        },
        {
            customersOnly: true,
            text: t('main.addLocation'),
            subText: t('main.addLocationText'),
            link: '/admin/add-location',
            icon: React.createElement(IconLocations, null),
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'content-title justify-content-space-between' },
            React.createElement("div", { className: 'title' },
                React.createElement("h2", null, t('main.admin')))),
        React.createElement(Grid, { stackable: true },
            React.createElement(GridRow, { columns: 3 }, items && items.map((item, key) => {
                return (item.customersOnly && isCustomerAccount || !item.customersOnly) && (React.createElement(GridColumn, { key: key },
                    React.createElement(Card, { className: 'stienen-card location-card mb2', component: Link, to: item.link },
                        React.createElement("div", { className: 'header-border' }),
                        React.createElement(CardActionArea, null,
                            React.createElement(CardContent, null,
                                React.createElement("div", { className: 'justify-content-center align-items-center' },
                                    React.createElement("div", null,
                                        React.createElement("div", { className: 'justify-content-center' }, item.icon),
                                        React.createElement("p", { className: 'text-transform-capitalize-first-letter' },
                                            React.createElement("b", null, item.text),
                                            React.createElement("br", null),
                                            React.createElement("i", null, item.subText)))))))));
            })))));
};
export default AdminRedirect;
