import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Sidebar, SidebarPushable, Divider } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import TabsList from 'app/widgets/components/Sidebar/TabTabs/TabsList';
import TabsListItem from 'app/widgets/components/Sidebar/TabTabs/TabsListItem';
import { storeColumnsSuccess } from 'app/analytics/state/slice';
import { orderBy } from 'lodash/fp';
import { Button, IconButton } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Form } from 'react-final-form';
import { SelectComponent } from '@formComponents/Select';
import { validateAnalyticsNewColumn } from '@validations/analytics';
const AnalyticsSidebar = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { visible, setVisible } = props;
    const [columnsItems, setColumnsItems] = useState([]);
    const columns = useSelector((state) => state && state.analytics && state.analytics.columns);
    const locations = useSelector((state) => state.locations && state.locations.list && state.locations.list.data);
    const listOfDevices = [];
    locations &&
        locations.map((location) => {
            location.devices &&
                location.devices.map((device) => {
                    listOfDevices.push({
                        key: device.id,
                        deviceId: device.id,
                        deviceName: device.name,
                        locationId: location.id,
                        locationName: location.name,
                        text: device.name,
                        value: device.id
                    });
                });
        });
    const listOfDevicesSorted = orderBy([(device) => device && device.locationName.toLowerCase(), (device) => device && device.deviceName.toLowerCase()], ['asc', 'asc'], listOfDevices);
    useEffect(() => {
        columns && columns.length > 0 && setColumnsItems(columns);
    }, [columns, visible]);
    const onCloseSideBar = () => {
        setVisible(false);
    };
    const onSaveEditMode = () => {
        const newArray = columnsItems.map((obj, key) => {
            return {
                index: key,
                deviceId: obj.deviceId,
                uuid: obj.uuid,
                name: obj.name
            };
        });
        dispatch(storeColumnsSuccess(newArray));
        setVisible(false);
    };
    const onDeleteTab = (uuid) => {
        const newArray = columnsItems.filter((obj) => {
            return obj.uuid !== uuid;
        });
        setColumnsItems(newArray);
    };
    const dragEnded = (param) => {
        const { source, destination } = param;
        const _arr = [...columnsItems];
        const _item = _arr.splice(source.index, 1)[0];
        _arr.splice(destination.index, 0, _item);
        setColumnsItems(_arr);
    };
    return (React.createElement(SidebarPushable, { className: 'sidebar-widgets' },
        React.createElement(Sidebar, { direction: 'right', animation: 'overlay', visible: visible },
            React.createElement(IconButton, { onClick: () => onCloseSideBar(), className: 'close-sidebar-btn' },
                React.createElement("span", { className: 'hover-bg' },
                    React.createElement(CloseOutlinedIcon, null))),
            React.createElement("div", { className: 'analytics-sidebar' },
                React.createElement("b", { className: 'label' }, t('analytics.newColumn')),
                React.createElement(Form, { onSubmit: () => { }, validate: validateAnalyticsNewColumn, render: ({ invalid, values }) => (React.createElement("form", null,
                        React.createElement(SelectComponent, { name: 'Device', label: t('analytics.device'), placeholder: t('analytics.device'), className: 'mt1 mb1-5', options: listOfDevicesSorted }),
                        values && values.Device &&
                            React.createElement(SelectComponent, { name: 'Column', label: t('analytics.column'), placeholder: t('analytics.column'), className: 'mb1-5', options: [] }),
                        React.createElement(Button, { disabled: invalid, variant: 'contained', className: 'float-right' }, t('main.add')))) }),
                React.createElement("br", null),
                React.createElement("br", null),
                React.createElement(Divider, { section: true }),
                React.createElement("b", { className: 'label' }, t('analytics.edit')),
                React.createElement(DragDropContext, { onDragEnd: dragEnded },
                    React.createElement(Droppable, { droppableId: 'tabItems-wrapper' }, (provided) => (React.createElement(TabsList, { ref: provided.innerRef, ...provided.droppableProps },
                        columnsItems && columnsItems.length > 0 && columnsItems.map((item, index) => {
                            return (React.createElement(Draggable, { draggableId: `item-${item.uuid}`, index: index, key: item.uuid }, (_provided, _snapshot) => (React.createElement(TabsListItem, { showDelete: true, ref: _provided.innerRef, dragHandleProps: _provided.dragHandleProps, ..._provided.draggableProps, snapshot: _snapshot, item: item, onDeleteTab: onDeleteTab, tabItems: columnsItems }))));
                        }),
                        provided.placeholder)))),
                React.createElement(Button, { variant: 'contained', className: 'float-right ml1', onClick: () => onSaveEditMode() }, t('main.save')),
                React.createElement(Button, { className: 'text-btn float-right', onClick: () => onCloseSideBar() }, t('main.cancel'))))));
};
export default AnalyticsSidebar;
