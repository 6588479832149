import React from 'react';
import { Step, StepLabel, Stepper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import WizardStepLicense from './LicenseModalWizard/WizardStepLicense';
import WizardStepLocation from './LicenseModalWizard/WizardStepLocation';
import WizardStepDevices from './LicenseModalWizard/WizardStepDevices';
const LicenseModalWizard = (props) => {
    const { t } = useTranslation();
    const { onClickLogout, activeStep, setActiveStep } = props;
    const steps = [
        t('auth.welcome'),
        t('auth.addLocation'),
        t('auth.connectDevices')
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Stepper, { activeStep: activeStep, alternativeLabel: true }, steps.map((label) => (React.createElement(Step, { key: label },
            React.createElement(StepLabel, null, label))))),
        activeStep === 0 ? React.createElement(WizardStepLicense, { onClickLogout: onClickLogout, setActiveStep: setActiveStep })
            : activeStep === 1 ? React.createElement(WizardStepLocation, { onClickLogout: onClickLogout, setActiveStep: setActiveStep })
                : activeStep === 2 ? React.createElement(WizardStepDevices, { onClickLogout: onClickLogout })
                    : null));
};
export default LicenseModalWizard;
