import { useSelector } from 'react-redux';
export const getNumberOfDevices = () => {
    const locations = useSelector((state) => state.locations && state.locations.list && state.locations.list.data);
    let numberOfDevices = 0;
    locations &&
        locations.map((location) => {
            location.devices.length > 0 &&
                location.devices.map(() => {
                    return numberOfDevices++;
                });
        });
    return numberOfDevices;
};
export const getPricesOfDevices = () => {
    const products = useSelector((state) => state.subscription && state.subscription.products && state.subscription.products.data);
    const locations = useSelector((state) => state.locations && state.locations.list && state.locations.list.data);
    const allPrices = [];
    locations &&
        locations.map((location) => {
            location.devices.length > 0 &&
                location.devices.map((device) => {
                    const hardware = products && products.find((product) => product.hardware === device.hardware);
                    hardware && hardware.unitAmount && allPrices.push(hardware.unitAmount);
                });
        });
    return allPrices;
};
export const getPricePerMonthInCents = () => {
    const pricesArray = getPricesOfDevices();
    const result = pricesArray.reduce((sum, num) => sum + num, 0);
    return result;
};
