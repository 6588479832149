import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { getHardwareById } from '@utils/hardware';
import IconBackup from '!svg-react-loader?name=IconBackup!assets/svg/icon-backup.svg';
import LocationInfoCards from './components/LocationInfoCards';
import { fetchLocations } from './state/slice';
import StienenTable from '@elementComponents/Table';
import { IconButton, Tooltip } from '@mui/material';
import DeviceStatusDot from 'app/layout/components/DeviceStatusDot';
import IconAlarms from '!svg-react-loader?name=IconAlarms!assets/svg/icon-alarm.svg';
import IconCalendar from '!svg-react-loader?name=IconCalendar!assets/svg/icon-calendar.svg';
import { promoMenu } from '@constants/promoMenu';
const PromoLocation = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { locationId, houseId } = useParams();
    const customers = useSelector((state) => state.customers && state.customers);
    const auth = useSelector((state) => state.auth && state.auth);
    const locationsIsLoaded = useSelector((state) => state.locations && state.locations.list && state.locations.list.isLoaded);
    const products = useSelector((state) => state.subscription && state.subscription.products && state.subscription.products.data);
    const userId = auth && auth.user && auth.user.userId;
    const customerId = customers && customers.current && customers.current.id;
    const findLocation = promoMenu && promoMenu.find((location) => location.id === locationId);
    const findHouse = findLocation && findLocation.subMenu && findLocation.subMenu.find((location) => location.id === houseId);
    useEffect(() => {
        auth && auth.success && userId && locationsIsLoaded !== true && dispatch(fetchLocations(customerId));
    }, [auth, userId]);
    const locationData = findHouse && products.length > 0 && findHouse.subMenu && findHouse.subMenu.length > 0 ? findHouse.subMenu.map((device) => {
        const hardware = getHardwareById(device.hardware, products);
        return {
            uuid: device.id,
            gatewayUuid: device.gatewayId,
            isLicenced: device.isLicenced,
            image: React.createElement("img", { src: hardware ? hardware.images[0] : 'https://dev.farmconnect.eu/img/UnavailableDevice.b0365816.png', style: { height: '50px' } }),
            name: device.name,
            hardware: hardware ? hardware.name : '-',
            address: device.address,
            version: device.version,
            online: React.createElement(DeviceStatusDot, { online: device.online }),
            logs: device.isLicenced === true &&
                React.createElement(React.Fragment, null,
                    React.createElement(Tooltip, { arrow: true, placement: 'top', title: t('locations.events') },
                        React.createElement(IconButton, { component: Link, className: 'table-action-btn always-show', to: `/locations/${device.gatewayId}/${device.id}/logs/events` },
                            React.createElement(IconCalendar, null))),
                    React.createElement(Tooltip, { arrow: true, placement: 'top', title: t('locations.alarms') },
                        React.createElement(IconButton, { component: Link, className: 'table-action-btn always-show', to: `/locations/${device.gatewayId}/${device.id}/logs/alarms` },
                            React.createElement(IconAlarms, null)))),
            backups: device.isLicenced === true &&
                React.createElement(Tooltip, { arrow: true, placement: 'top', title: t('locations.backups') },
                    React.createElement(IconButton, { component: Link, className: 'table-action-btn always-show', to: `/locations/${device.gatewayId}/${device.id}/backups` },
                        React.createElement(IconBackup, null)))
        };
    }) : [];
    const headers = [
        {
            id: 'image',
            label: t('locations.image'),
        },
        {
            id: 'name',
            label: t('locations.name'),
        },
        {
            id: 'hardware',
            label: t('locations.hardware')
        },
        {
            id: 'address',
            numeric: true,
            label: t('locations.address')
        },
        {
            id: 'version',
            numeric: true,
            label: t('locations.version')
        },
        {
            id: 'online',
            label: t('locations.online')
        },
        {
            id: 'isLicenced',
            label: t('locations.license'),
            booleanDots: true
        },
        {
            id: 'logs',
            label: t('locations.logs'),
        },
        {
            id: 'backups',
            label: t('locations.backups'),
        }
    ];
    const onSelectDevice = (device) => {
        history.push(`/locations/${device.gatewayUuid}/${device.uuid}`);
    };
    const isLoading = false;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'content-title justify-content-space-between' },
            React.createElement("div", { className: 'title' },
                React.createElement("h2", null, findHouse && findHouse.name))),
        findHouse && React.createElement(LocationInfoCards, { locationInfo: findHouse }),
        React.createElement("h3", null, t('locations.devices')),
        React.createElement(StienenTable, { searchBar: true, showCheckboxes: true, isLoading: isLoading, numberOfRows: 10, headers: headers, rows: locationData && locationData, defaultOrder: 'name', defaultOrderDesc: true, title: t('locations.devices'), actions: [
                {
                    button: t('main.select'),
                    onlyShowIfColumnIsTrue: 'isLicenced',
                    onClick: onSelectDevice
                }
            ] })));
};
export default PromoLocation;
