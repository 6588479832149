var React = require('react');

function IconDevice (props) {
    return React.createElement("svg",props,React.createElement("g",null,React.createElement("g",{"id":"Layer_1"},React.createElement("g",null,[React.createElement("path",{"d":"M11,0C4.935,0,0,4.935,0,11s4.935,11,11,11,11-4.935,11-11S17.065,0,11,0ZM11,20.976c-5.501,0-9.976-4.475-9.976-9.976S5.499,1.024,11,1.024s9.976,4.475,9.976,9.976-4.475,9.976-9.976,9.976Z","key":0}),React.createElement("path",{"d":"M10.326,6.071c0-.372.302-.674.674-.674s.674.302.674.674-.302.674-.674.674-.674-.302-.674-.674Z","key":1}),React.createElement("path",{"d":"M11,8.106c-.283,0-.512.229-.512.512v8.335c0,.283.229.512.512.512s.512-.229.512-.512v-8.335c0-.283-.229-.512-.512-.512Z","key":2})]))));
}

IconDevice.defaultProps = {"version":"1.1","viewBox":"0 0 22 22"};

module.exports = IconDevice;

IconDevice.default = IconDevice;
