import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
const UserProfile = (props) => {
    const { sidebar, pagePath } = props;
    const user = useSelector((state) => state.auth && state.auth.user);
    const emailAddress = user && user.email;
    const name = user && user.firstName && user.lastName && `${user.firstName} ${user.lastName}`;
    return (pagePath.includes('locations')
        || pagePath.includes('admin')) && sidebar === 'open' ? (React.createElement(React.Fragment, null,
        React.createElement(Link, { to: '/settings', className: 'user-profile' },
            React.createElement("p", { className: 'user-name' }, name ? name : emailAddress ? emailAddress : ''),
            React.createElement("div", { className: 'user-image user-characters' },
                React.createElement("img", { src: require('../../../../../src/assets/images/profile-picture-francois.jpg') }))),
        React.createElement("div", { className: 'user-profile-sub-header' }))) : (React.createElement(Link, { to: '/settings', className: 'user-profile-sidebar-closed' },
        React.createElement("p", { className: 'user-name' },
            React.createElement("span", null, name ? name : emailAddress ? emailAddress : '')),
        React.createElement("div", { className: 'user-image user-characters' },
            React.createElement("h1", null,
                React.createElement("img", { src: require('../../../../../src/assets/images/profile-picture-francois.jpg') })))));
};
export default UserProfile;
