import { createSlice } from '@reduxjs/toolkit';
import { getMetrics, getProducts, getSubscription } from './api';
const initialState = {
    metrics: {},
    products: {
        data: [],
        loading: false,
        isLoaded: false,
        error: ''
    },
    subscription: {}
};
const subscription = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        getMetricsSuccess(state, action) {
            state.metrics = action.payload;
        },
        getMetricsFailure(_state, action) {
            console.log(action.payload);
        },
        clearMetricsSuccess(state) {
            state.metrics = {};
        },
        getProductsStart(state) {
            state.products.loading = true;
        },
        getProductsSuccess(state, action) {
            state.products.data = action.payload;
            state.products.loading = false;
            state.products.isLoaded = true;
        },
        getProductsFailure(state, action) {
            state.products.loading = false;
            state.products.isLoaded = false;
            state.products.error = action.payload;
            console.log(action.payload);
        },
        clearProductsSuccess(state) {
            state.products.data = [],
                state.products.loading = false,
                state.products.isLoaded = false,
                state.products.error = '';
        },
        getSubscriptionSuccess(state, action) {
            state.subscription = action.payload;
        },
        getSubscriptionFailure(_state, action) {
            console.log(action.payload);
        },
        clearSubscriptionSuccess(state) {
            state.subscription = {};
        },
    }
});
export const { getMetricsSuccess, getMetricsFailure, getProductsStart, getProductsSuccess, getProductsFailure, clearMetricsSuccess, clearProductsSuccess, getSubscriptionSuccess, getSubscriptionFailure, clearSubscriptionSuccess } = subscription.actions;
export default subscription.reducer;
export const fetchMetrics = (companyId) => async (dispatch) => {
    try {
        const metrics = await getMetrics(companyId);
        dispatch(getMetricsSuccess(metrics));
    }
    catch (err) {
        dispatch(getMetricsFailure(err && err.message));
    }
};
export const fetchProducts = () => async (dispatch) => {
    try {
        dispatch(getProductsStart());
        const productsList = await getProducts();
        dispatch(getProductsSuccess(productsList));
    }
    catch (err) {
        dispatch(getProductsFailure(err && err.message));
    }
};
export const fetchSubscription = (customerId) => async (dispatch) => {
    try {
        const SubscriptionList = await getSubscription(customerId);
        dispatch(getSubscriptionSuccess(SubscriptionList));
    }
    catch (err) {
        dispatch(getSubscriptionFailure(err && err.message));
    }
};
