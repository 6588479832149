import React, { useState } from 'react';
import IconLogout from '!svg-react-loader?name=IconLogout!assets/svg/icon-logout.svg';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { toastrSuccess } from '@elementComponents/Toastr';
import { useDispatch, useSelector } from 'react-redux';
import { logoutSuccess } from 'app/login/state/slice';
import { clearCustomerSuccess, clearCustomersSuccess } from 'app/customers/state/slice';
import { clearLocationsSuccess } from 'app/locations/state/slice';
import { clearDealerInvitesSuccess } from 'app/dealer/state/slice';
import { clearEventLogSuccess } from 'app/locations/components/Logs/eventLog/state/slice';
import { clearMetricsSuccess, clearProductsSuccess } from 'app/admin/subscription/state/slice';
import { Box, Button, Modal } from '@mui/material';
import { StienenIconButtonWithTooltip } from '@elementComponents/IconButton';
import { ModalCloseBtn } from '@elementComponents/ModalCloseBtn';
const LogoutButton = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useSelector((state) => state.auth && state.auth);
    const isMechanicAccount = auth && auth.user && auth.user.isMechanicAccount;
    const isServiceAccount = auth && auth.user && auth.user.isServiceAccount;
    const [open, setOpen] = useState(false);
    const { mobileMenu, setOpenMobile } = props;
    const onCloseModal = (_event, reason) => {
        if (reason && reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
        }
        setOpen(false);
        setOpenMobile && setOpenMobile(false);
    };
    const onClickLogoutOut = async () => {
        Auth.signOut()
            .then(() => {
            toastrSuccess(t('auth.logoutMessageHeader'), t('auth.logoutMessageText'));
            dispatch(logoutSuccess());
            dispatch(clearCustomersSuccess());
            dispatch(clearCustomerSuccess());
            dispatch(clearLocationsSuccess());
            dispatch(clearDealerInvitesSuccess());
            dispatch(clearEventLogSuccess());
            dispatch(clearMetricsSuccess());
            dispatch(clearProductsSuccess());
            onCloseModal('', 'closeButtonClick');
            history.push('/login');
        })
            .catch((err) => console.log(err));
    };
    const onClickDealerLogout = () => {
        dispatch(clearCustomerSuccess());
        dispatch(clearLocationsSuccess());
        dispatch(clearDealerInvitesSuccess());
        dispatch(clearEventLogSuccess());
        dispatch(clearMetricsSuccess());
        dispatch(clearProductsSuccess());
        onCloseModal('', 'closeButtonClick');
        history.push('/select-customer');
    };
    const showModal = (isMechanicAccount || isServiceAccount);
    return mobileMenu ? (React.createElement("div", { className: 'mobile-logout-btns' },
        showModal &&
            React.createElement(Button, { className: 'text-btn', onClick: () => onClickDealerLogout() }, t('main.switchCustomer')),
        React.createElement(Button, { variant: 'contained', onClick: () => onClickLogoutOut() }, t('main.logout')))) : (React.createElement(React.Fragment, null,
        React.createElement(StienenIconButtonWithTooltip, { title: showModal ? t('main.logoutOrSwitchCustomer') : t('main.logout'), onClick: showModal ? () => setOpen(true) : () => onClickLogoutOut(), content: React.createElement(IconLogout, null) }),
        React.createElement(Modal, { open: open, onClose: onCloseModal, className: 'stienen-modal' },
            React.createElement(Box, null,
                React.createElement(ModalCloseBtn, { onClick: () => onCloseModal('', 'closeButtonClick') }),
                React.createElement("h2", { className: 'modal-title text-align-center m0' }, t('main.logout')),
                React.createElement("div", { className: 'modal-content text-align-center' },
                    React.createElement("p", null, t('main.logoutModalText')),
                    React.createElement(Button, { className: 'text-btn mr1', onClick: () => onClickDealerLogout() }, t('main.switchCustomer')),
                    React.createElement(Button, { variant: 'contained', onClick: () => onClickLogoutOut() }, t('main.logout')))))));
};
export default LogoutButton;
