import { createSlice } from '@reduxjs/toolkit';
import { getAlarmHistoryRange } from './api';
const initialState = {
    active: {
        data: [],
        loading: false,
        isLoaded: false,
        lastUpdate: ''
    },
    device: {
        data: [],
        loading: false,
        isLoaded: false,
        error: ''
    }
};
const alarms = createSlice({
    name: 'alarms',
    initialState,
    reducers: {
        storeAlarmSuccess(state, action) {
            const oldArray = state && state.active && state.active.data;
            const newArray = oldArray && oldArray.concat(action.payload);
            const dateTime = new Date().toISOString();
            state.active.data = newArray;
            state.active.loading = false;
            state.active.isLoaded = true;
            state.active.lastUpdate = dateTime;
        },
        removeAlarmSuccess(state, action) {
            const oldArray = state && state.active && state.active.data;
            const newArray = oldArray.filter((x) => x.did !== action.payload[0].did);
            const dateTime = new Date().toISOString();
            state.active.data = newArray;
            state.active.loading = false;
            state.active.isLoaded = false;
            state.active.lastUpdate = dateTime;
        },
        clearAlarmsSuccess(state) {
            state.active.data = [];
            state.active.lastUpdate = '';
        },
        getAlarmHistoryRangeStart(state) {
            state.device.loading = true;
        },
        getAlarmHistoryRangeSuccess(state, action) {
            state.device.data = action.payload;
            state.device.loading = false;
            state.device.isLoaded = true;
            state.device.error = '';
        },
        getAlarmHistoryRangeFailure(state, action) {
            state.device.loading = false;
            state.device.isLoaded = false;
            state.device.error = action.payload;
            console.log(action.payload);
        },
        clearAlarmHistoryRangeSuccess(state) {
            state.device.data = [],
                state.device.loading = false,
                state.device.isLoaded = false,
                state.device.error = '';
        }
    }
});
export const { storeAlarmSuccess, removeAlarmSuccess, clearAlarmsSuccess, getAlarmHistoryRangeStart, getAlarmHistoryRangeSuccess, getAlarmHistoryRangeFailure, clearAlarmHistoryRangeSuccess } = alarms.actions;
export default alarms.reducer;
export const fetchAlarmHistoryRange = (device, start, end) => async (dispatch) => {
    try {
        dispatch(clearAlarmHistoryRangeSuccess());
        dispatch(getAlarmHistoryRangeStart());
        const alarmHistoryRangeList = await getAlarmHistoryRange(device, start, end);
        dispatch(getAlarmHistoryRangeSuccess(alarmHistoryRangeList));
    }
    catch (err) {
        dispatch(getAlarmHistoryRangeFailure(err && err.message));
    }
};
