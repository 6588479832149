import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomer, fetchCustomers } from 'app/customers/state/slice';
import { fetchLocations } from 'app/locations/state/slice';
import { fetchUser } from 'app/admin/users/state/slice';
import { fetchProducts } from 'app/admin/subscription/state/slice';
import { fetchDealerInvites } from 'app/dealer/state/slice';
const MainApiCalls = () => {
    const dispatch = useDispatch();
    const customers = useSelector((state) => state.customers && state.customers);
    const auth = useSelector((state) => state.auth && state.auth);
    const currentCustomer = useSelector((state) => state.customers && state.customers.current);
    const customersIsLoaded = useSelector((state) => state.customers && state.customers.list && state.customers.list.isLoaded);
    const locationsIsLoaded = useSelector((state) => state.locations && state.locations.list && state.locations.list.isLoaded);
    const customerId = customers && customers.current && customers.current.id;
    const userId = auth && auth.user && auth.user.userId;
    const isCustomerAccount = auth && auth.user && auth.user.isCustomerAccount;
    const isServiceAccount = auth && auth.user && auth.user.isServiceAccount;
    const isMechanicAccount = auth && auth.user && auth.user.isMechanicAccount;
    useEffect(() => {
        auth && auth.success && dispatch(fetchProducts());
        auth && auth.success && userId && dispatch(fetchUser(userId));
        auth && auth.success && customerId && dispatch(fetchDealerInvites(customerId));
    }, [userId, customerId]);
    useEffect(() => {
        auth && auth.success && customerId && currentCustomer && !currentCustomer.id && dispatch(fetchCustomer(customerId));
    }, [auth, customerId, currentCustomer]);
    useEffect(() => {
        auth && auth.success && isCustomerAccount && currentCustomer && !currentCustomer.id && dispatch(fetchCustomer(userId));
    }, [auth, isCustomerAccount, userId]);
    useEffect(() => {
        auth && auth.success && !customerId && isServiceAccount && !isMechanicAccount && customersIsLoaded !== true && dispatch(fetchCustomers());
    }, [customerId, isServiceAccount, isMechanicAccount]);
    useEffect(() => {
        auth && auth.success && userId && locationsIsLoaded !== true && isCustomerAccount && currentCustomer && customerId && dispatch(fetchLocations(customerId));
    }, [userId, locationsIsLoaded, currentCustomer, isCustomerAccount]);
    return null;
};
export default MainApiCalls;
