import i18n from '../i18n';
export const validateAdminUsers = (values) => {
    const errors = {};
    if (!values.FirstName) {
        errors.FirstName = i18n.t('validations.required');
    }
    if (!values.LastName) {
        errors.LastName = i18n.t('validations.required');
    }
    if (!values.EmailAddress) {
        errors.EmailAddress = i18n.t('validations.required');
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.EmailAddress)) {
        errors.EmailAddress = i18n.t('validations.invalidEmailAddress');
    }
    if (!values.RoleKey) {
        errors.RoleKey = i18n.t('validations.required');
    }
    return errors;
};
export const validateAdminDealers = (values) => {
    const errors = {};
    if (!values.EmailAddress) {
        errors.EmailAddress = i18n.t('validations.required');
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.EmailAddress)) {
        errors.EmailAddress = i18n.t('validations.invalidEmailAddress');
    }
    return errors;
};
export const validateAdminCompanyName = (values) => {
    const errors = {};
    if (!values.CompanyName) {
        errors.CompanyName = i18n.t('validations.required');
    }
    else if (values.CompanyName.length < 3) {
        errors.CompanyName = i18n.t('validations.theMinimumLengthofaCompanyNameMustBe3Characters');
    }
    return errors;
};
export const validateAdminAddLocation = (values) => {
    const errors = {};
    if (!values.Name) {
        errors.Name = i18n.t('validations.required');
    }
    else if (values.Name.length < 3) {
        errors.Name = i18n.t('validations.theMinimumLengthofaNameMustBe3Characters');
    }
    const licenseKeyWithoutUnderscores = values.LicenseKey?.replace(/_/g, '');
    const macAddressWithoutUnderscores = values.MacAddress?.replace(/_/g, '');
    if (!values.LicenseKey) {
        errors.LicenseKey = i18n.t('validations.required');
    }
    else if (licenseKeyWithoutUnderscores.length !== 17) {
        errors.LicenseKey = i18n.t('validations.theLengthofaLicenseKeyMustBe12Characters');
    }
    if (!values.MacAddress) {
        errors.MacAddress = i18n.t('validations.required');
    }
    else if (macAddressWithoutUnderscores.length !== 17) {
        errors.MacAddress = i18n.t('validations.theLengthofaMacAddressMustBe12Characters');
    }
    return errors;
};
