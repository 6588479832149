import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { waterLineChartOptions, weightLineChartOptions } from '@constants/widgets';
import highchartsMore from 'highcharts/highcharts-more';
highchartsMore(Highcharts);
const LineChartWidget = (props) => {
    const { content: { title } } = props;
    const extraOptions = title === 'Water' ? waterLineChartOptions()
        : title === 'Weight' ? weightLineChartOptions()
            : [];
    const options = {
        title: false,
        xAxis: [
            {
                categories: extraOptions.xAxis && extraOptions.xAxis.categories && extraOptions.xAxis.categories,
                plotLines: extraOptions.xAxis && extraOptions.xAxis.plotLines && extraOptions.xAxis.plotLines,
                crosshair: true
            }
        ],
        yAxis: [
            {
                title: null,
                labels: {
                    format: extraOptions.yAxisLabel
                }
            },
            {
                title: false,
                opposite: true
            }
        ],
        colors: extraOptions.colors,
        tooltip: {
            shared: true
        },
        series: extraOptions.seriesData,
        plotOptions: {
            series: {
                showInLegend: true
            }
        }
    };
    return (React.createElement("div", { className: 'widget-line-chart' },
        React.createElement(HighchartsReact, { highcharts: Highcharts, options: options })));
};
export default LineChartWidget;
