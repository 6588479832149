import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { startOfDay, sub } from 'date-fns';
import { dateWithoutTimezone, formatToDutchDateAndTime } from '@utils/dates';
import { useParams } from 'react-router-dom';
import { getDeviceById } from '@utils/devices';
import { fetchAlarmHistoryRange } from 'app/alarms/state/slice';
import { fetchAlarmReference, fetchControlReference } from 'app/reference/state/slice';
import { getCookieLocale } from '@utils/locales';
import StienenTable from '@elementComponents/Table';
import { Form } from 'react-final-form';
import { DateRangePickerComponent } from '@formComponents/DatePicker';
import isToday from 'date-fns/isToday';
import endOfDay from 'date-fns/endOfDay';
const AlarmsHistory = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { deviceId } = useParams();
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [startApi, setStartApi] = useState('');
    const [endApi, setEndApi] = useState('');
    const alarmsList = useSelector((state) => state.alarms && state.alarms.device && state.alarms.device.data);
    const alarmsListIsLoaded = useSelector((state) => state.alarms && state.alarms.device && state.alarms.device.isLoaded);
    const alarmReference = useSelector((state) => state.reference && state.reference.alarm);
    const controlReference = useSelector((state) => state.reference && state.reference.control);
    const locations = useSelector((state) => state.locations && state.locations.list && state.locations.list.data);
    console.log('locations=', locations);
    const device = getDeviceById(deviceId, locations);
    const versionAndHardwareList = [];
    const language = getCookieLocale().code;
    useEffect(() => {
        const numberOfDays = 14;
        const currentDate = new Date();
        const endDate = currentDate;
        const startDate = sub(currentDate, { days: numberOfDays });
        const endDateApi = dateWithoutTimezone(currentDate);
        const startDateApi = dateWithoutTimezone(startOfDay(sub(currentDate, { days: numberOfDays })));
        startDate && (setStart(startDate),
            setStartApi(startDateApi));
        endDate && (setEnd(endDate),
            setEndApi(endDateApi));
    }, []);
    useEffect(() => {
        device && device.id && startApi && endApi && (console.log('fetchAlarmHistoryRange'),
            dispatch(fetchAlarmHistoryRange(device.id, startApi, endApi)));
    }, [startApi, endApi, device]);
    useEffect(() => {
        console.log('useEffect alarmsList');
        alarmsList && alarmsList.map((alarm) => {
            console.log('versionAndHardwareList=', versionAndHardwareList);
            console.log('alarmObject');
            console.log('alarm=', alarm);
            const findVersionAndHardware = versionAndHardwareList.find((versionAndAlarm) => versionAndAlarm.hardware === alarm.hardware && versionAndAlarm.version === alarm.version && versionAndAlarm.language === language);
            console.log('findVersionAndHardware=', findVersionAndHardware);
            if (!findVersionAndHardware) {
                console.log('add to list!!');
                versionAndHardwareList.push({
                    hardware: alarm.hardware,
                    version: alarm.version,
                    language: language
                });
                dispatch(fetchAlarmReference(alarm.hardware, alarm.version, language));
                dispatch(fetchControlReference(alarm.hardware, alarm.version, language));
            }
        });
    }, [alarmsList]);
    const onChangeDateRange = (date) => {
        setStart(date[0]);
        setStartApi(dateWithoutTimezone(date[0]));
        const isDateToday = isToday(date[1]);
        setEnd(date[1]);
        setEndApi(isDateToday ? dateWithoutTimezone(new Date()) : dateWithoutTimezone(endOfDay(date[1])));
    };
    const alarmHistoryData = alarmsList && alarmsList.map((alarm) => {
        const hardware = alarm.hardware;
        const version = alarm.version;
        const findAlarmReferenceData = alarmReference && alarmReference.find((ref) => ref.hardware === hardware && ref.version === version && ref.language === language)?.data;
        const alarmReferenceText = findAlarmReferenceData && findAlarmReferenceData.find((ref) => ref.Label === alarm.alarmCode.toString());
        const findControlReferenceData = controlReference && controlReference.find((ref) => ref.hardware === hardware && ref.version === version && ref.language === language)?.data;
        const controlReferenceText = findControlReferenceData && findControlReferenceData.find((ref) => ref.Label === alarm.controlNr.toString());
        return {
            uuid: alarm.id,
            timeStamp: alarm.timeStamp && formatToDutchDateAndTime(alarm.timeStamp),
            control: controlReferenceText && controlReferenceText.Text ? controlReferenceText.Text : alarm.controlNr,
            alarmMessage: alarmReferenceText && alarmReferenceText.Text ? alarmReferenceText.Text : alarm.alarmCode,
            status: '-',
        };
    });
    const headers = [
        {
            id: 'timeStamp',
            label: t('alarms.dateAndTime'),
        },
        {
            id: 'control',
            label: t('alarms.control')
        },
        {
            id: 'alarmMessage',
            label: t('alarms.alarmMessage'),
        },
        {
            id: 'status',
            label: t('alarms.status'),
        },
    ];
    const numberOfAlarms = alarmsList && alarmsList.length;
    const isLoading = alarmsListIsLoaded && (alarmsList && alarmsList.length > 0) && (alarmHistoryData && alarmHistoryData.length > 0)
        ? false
        : alarmsListIsLoaded && (alarmsList && alarmsList.length === 0)
            ? false
            : true;
    return (React.createElement("div", null,
        React.createElement("div", { className: 'content-title justify-content-space-between' },
            React.createElement("div", { className: 'title' },
                React.createElement("h2", null, device && device.name))),
        React.createElement("div", { className: 'justify-content-space-between mb1' },
            React.createElement("div", { className: 'justify-content-start' },
                React.createElement(Form, { onSubmit: () => { }, render: () => (React.createElement("form", { className: 'justify-content-start' },
                        React.createElement(DateRangePickerComponent, { defaultValue: [start, end], cleanable: false, name: 'DateRange', label: t('analytics.dateRange'), onChangeDate: onChangeDateRange }))) }))),
        React.createElement("p", { className: 'text' },
            t('alarms.alarmsText1'),
            React.createElement("span", { className: 'bold' }, numberOfAlarms && numberOfAlarms),
            t('alarms.alarmsText2')),
        React.createElement(StienenTable, { searchBar: true, showCheckboxes: true, isLoading: isLoading, numberOfRows: 10, headers: headers, rows: alarmHistoryData.length > 0 ? alarmHistoryData : [], defaultOrder: 'timeStamp', defaultOrderDesc: true, title: t('main.alarms') })));
};
export default AlarmsHistory;
