import React from 'react';
import { toast } from 'react-semantic-toasts';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
export const toastrSuccess = (title, description) => {
    const options = {
        title,
        description,
        type: 'success',
        icon: React.createElement(CheckCircleOutlineRoundedIcon, null),
        time: 5000
    };
    return toast(options);
};
export const toastrError = (title, description) => {
    const options = {
        title,
        description,
        type: 'error',
        icon: React.createElement(ErrorOutlineRoundedIcon, null),
        time: 5000
    };
    return toast(options);
};
export const toastrWarning = (title, description) => {
    const options = {
        title,
        description,
        type: 'warning',
        icon: React.createElement(WarningAmberRoundedIcon, null),
        time: 5000
    };
    return toast(options);
};
export const toastrInfo = (title, description) => {
    const options = {
        title,
        description,
        type: 'info',
        icon: React.createElement(HelpOutlineRoundedIcon, null),
        time: 5000
    };
    return toast(options);
};
export const toastrAlarm = (title, description) => {
    const options = {
        title,
        description,
        type: 'error',
        icon: React.createElement(NotificationsActiveOutlinedIcon, null),
        time: 5000
    };
    return toast(options);
};
