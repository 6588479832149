import React from 'react';
import IconSettings from '!svg-react-loader?name=IconSettings!assets/svg/icon-cog.svg';
import IconAlarm from '!svg-react-loader?name=IconAlarms!assets/svg/icon-alarm.svg';
import IconAlarmActive from '!svg-react-loader?name=IconAlarmActives!assets/svg/icon-alarm-active.svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LogoutModal from './LogoutButton';
import { StienenIconButtonWithTooltip } from '@elementComponents/IconButton';
const Icons = (props) => {
    const { t } = useTranslation();
    const alarms = useSelector((state) => state.alarms && state.alarms.active && state.alarms.active.data);
    const hasAlarms = alarms && alarms.length > 0;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'icons' },
            React.createElement(StienenIconButtonWithTooltip, { title: t('main.alarms'), link: '/alarms', className: hasAlarms ? 'icon-active-alarm' : '', onClick: () => props.onClickSidebarBtn(false), content: hasAlarms
                    ? React.createElement(IconAlarmActive, null)
                    : React.createElement(IconAlarm, null) }),
            React.createElement(StienenIconButtonWithTooltip, { title: t('main.settings'), link: '/settings', content: React.createElement(IconSettings, null) }),
            React.createElement(LogoutModal, null))));
};
export default Icons;
