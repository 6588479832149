import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSignalRContext } from 'react-signalr/signalr';
import { removeAlarmSuccess, storeAlarmSuccess } from 'app/alarms/state/slice';
import { toastrAlarm } from '@elementComponents/Toastr';
import { useTranslation } from 'react-i18next';
const SignalRContext = createSignalRContext();
const HeaderAlarms = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const customers = useSelector((state) => state.customers && state.customers);
    const auth = useSelector((state) => state.auth && state.auth);
    const customerId = customers && customers.current && customers.current.id;
    const accessToken = auth &&
        auth.user &&
        auth.user.jwtToken;
    const WS_URL = `${process.env.SIGNALR_API}/hub?customerId=${customerId}`;
    SignalRContext.useSignalREffect('liveAlarmUpdate', (alarm) => {
        console.log('alarm=', alarm);
        alarm.alarms[0].alarmCode === 0 && alarm.alarms[0].controlNr === 0 && alarm.alarms[0].controlText === 0 && alarm.alarms[0].controlType === 0
            ? (dispatch(removeAlarmSuccess(alarm.alarms))) : (dispatch(storeAlarmSuccess(alarm.alarms)),
            toastrAlarm(t('alarms.alarm'), `${t('alarms.alarmToastrErrorText', { name: alarm.alarms[0].deviceName })}`));
    }, accessToken);
    SignalRContext.useSignalREffect('deviceUpdate', (e) => { console.log('DEVICE UPDATE = ', e); }, accessToken);
    SignalRContext.useSignalREffect('setValuesResult', (e) => { console.log('SET VALUES RESULT = ', e); }, accessToken);
    SignalRContext.useSignalREffect('onDataUpdate', (e) => { console.log('ON DATE UPDATE = ', e); }, accessToken);
    SignalRContext.useSignalREffect('locationUpdatedOnline', (e) => { console.log('LOCATION UPDATED ONLINE = ', e); }, accessToken);
    SignalRContext.useSignalREffect('deviceUpdatedOnline', (e) => { console.log('DEVICE UPDATED ONLINE = ', e); }, accessToken);
    return (React.createElement(SignalRContext.Provider, { automaticReconnect: true, connectEnabled: !!accessToken, accessTokenFactory: () => accessToken, dependencies: [accessToken], url: WS_URL, onOpen: () => console.log('WEBSOCKET CONNECTION OPEN'), onClosed: () => console.log('WEBSOCKET CONNECTION CLOSED'), onError: (err) => console.log('WEBSOCKET ERROR = ', err), onBeforeClose: () => console.log('WEBSOCKET BEFORE CLOSE') }));
};
export default HeaderAlarms;
