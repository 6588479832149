import SubMenuTestMenu from 'app/layout/components/Sidebar/SubMenuTestMenu';
import React from 'react';
const MenuPlayGround = () => {
    return (React.createElement("div", null,
        React.createElement("div", { className: 'content-title justify-content-space-between' },
            React.createElement("div", { className: 'title' },
                React.createElement("h2", null, "Menu PlayGround"))),
        React.createElement("div", { className: 'menu-playgrounds-block without-hover-effect' },
            React.createElement("div", { className: 'menu-example menu-example-1' },
                React.createElement(SubMenuTestMenu, { example: 1 }),
                React.createElement("div", { className: 'tekst' },
                    React.createElement("ul", null,
                        React.createElement("li", null,
                            "Tekst ",
                            React.createElement("b", null,
                                React.createElement("u", null, "links")),
                            " uitgelijnd."),
                        React.createElement("li", null,
                            React.createElement("b", null,
                                React.createElement("u", null, "Spaties")),
                            " (2) toegevoegd per niveau.")))),
            React.createElement("div", { className: 'menu-example menu-example-2' },
                React.createElement(SubMenuTestMenu, { example: 2 }),
                React.createElement("div", { className: 'tekst' },
                    React.createElement("ul", null,
                        React.createElement("li", null,
                            "Geen gradiant kleuren, maar volle ",
                            React.createElement("b", null,
                                React.createElement("u", null, "blauwe")),
                            " kleuren, die steeds  ",
                            React.createElement("b", null,
                                React.createElement("u", null, "donkerder")),
                            " worden."),
                        React.createElement("li", null, "Alle lijnen weg."),
                        React.createElement("li", null,
                            "Tekst ",
                            React.createElement("b", null,
                                React.createElement("u", null, "links")),
                            " uitgelijnd."),
                        React.createElement("li", null,
                            React.createElement("b", null,
                                React.createElement("u", null, "Spaties")),
                            " (2) toegevoegd per niveau.")))),
            React.createElement("div", { className: 'menu-example menu-example-3' },
                React.createElement(SubMenuTestMenu, { example: 3 }),
                React.createElement("div", { className: 'tekst' },
                    React.createElement("ul", null,
                        React.createElement("li", null,
                            "Geen gradiant kleuren, maar volle ",
                            React.createElement("b", null,
                                React.createElement("u", null, "blauwe")),
                            " kleuren, die steeds  ",
                            React.createElement("b", null,
                                React.createElement("u", null, "donkerder")),
                            " worden."),
                        React.createElement("li", null,
                            "Alleen de ",
                            React.createElement("b", null,
                                React.createElement("u", null, "parent items")),
                            " hebben nog lijnen (#414141)."),
                        React.createElement("li", null,
                            "Tekst ",
                            React.createElement("b", null,
                                React.createElement("u", null, "links")),
                            " uitgelijnd."),
                        React.createElement("li", null,
                            React.createElement("b", null,
                                React.createElement("u", null, "Spaties")),
                            " (2) toegevoegd per niveau."),
                        React.createElement("li", null,
                            "De ",
                            React.createElement("b", null,
                                React.createElement("u", null, "actieve")),
                            " items zitten tussen wit en grijs in qua kleur (#BFBFBF)."),
                        React.createElement("li", null,
                            "Alleen ",
                            React.createElement("b", null,
                                React.createElement("u", null, "laatste actieve")),
                            " item is wit.")))))));
};
export default MenuPlayGround;
