import { nl, enGB, de } from 'date-fns/locale';
import Cookies from 'universal-cookie';
export const getCookieLocale = () => {
    const cookies = new Cookies();
    const locale = cookies.get('language') === 'nl' ? nl
        : cookies.get('language') === 'en' ? enGB
            : cookies.get('language') === 'de' ? de
                : enGB;
    return locale;
};
