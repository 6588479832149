import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from '@state/store';
import i18n from '../i18n';
import FarmConnect from './Container';
import { Authenticator } from '@aws-amplify/ui-react';
import '@utils/amplify';
import LoadingScreen from './layout/components/LoadingScreen';
import { SemanticToastContainer } from 'react-semantic-toasts';
import CompanyColor from './layout/components/CompanyColor';
import UserSession from './layout/components/UserSession';
import MainApiCalls from './layout/components/MainApiCalls';
const Application = () => {
    return (React.createElement(Authenticator.Provider, null,
        React.createElement(I18nextProvider, { i18n: i18n },
            React.createElement(Provider, { store: store },
                React.createElement(PersistGate, { loading: null, persistor: persistor },
                    React.createElement(UserSession, null),
                    React.createElement(CompanyColor, null),
                    React.createElement(Suspense, { fallback: React.createElement(LoadingScreen, null) },
                        React.createElement(Router, null,
                            React.createElement(SemanticToastContainer, { position: 'bottom-right', maxToasts: 8, animation: 'bounce' }),
                            React.createElement(MainApiCalls, null),
                            React.createElement(FarmConnect, null))))))));
};
export default Application;
