import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, GridColumn, GridRow } from 'semantic-ui-react';
import IconHome from '!svg-react-loader?name=IconHome!assets/svg/icon-barn2.svg';
import { onClickItem } from '@utils/menu';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import { promoMenu } from '@constants/promoMenu';
const PromoHouses = () => {
    const pagePath = location.pathname;
    const pathArray = pagePath.split('/');
    const pathArrayWithoutEmpty = pathArray.filter((elm) => elm);
    const house = pathArrayWithoutEmpty[1];
    const houseObject = house && promoMenu && promoMenu.find((item) => item.id === house);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'content-title justify-content-space-between' },
            React.createElement("div", { className: 'title' },
                React.createElement("h2", null, houseObject && houseObject.name))),
        React.createElement(Grid, { stackable: true },
            React.createElement(GridRow, { columns: 3 }, houseObject && houseObject.subMenu && houseObject.subMenu.length > 0 && houseObject.subMenu.map((house, key) => {
                return (React.createElement(GridColumn, { key: key },
                    React.createElement(Card, { className: 'stienen-card location-card mb2', component: Link, to: `/promo-locations/${houseObject.id}/${house.id}`, onClick: () => onClickItem(house.id, 1) },
                        React.createElement("div", { className: 'header-border' }),
                        React.createElement(CardActionArea, null,
                            React.createElement(CardContent, null,
                                React.createElement("div", { className: 'justify-content-center align-items-center' },
                                    React.createElement("div", null,
                                        React.createElement("div", { className: 'justify-content-center' },
                                            React.createElement(IconHome, null)),
                                        React.createElement("p", { className: 'text-transform-capitalize-first-letter' },
                                            React.createElement("b", null, house.name),
                                            React.createElement("br", null),
                                            React.createElement("i", null,
                                                house.subMenu && house.subMenu.length > 0 ? house.subMenu.length : 0,
                                                " Devices")))))))));
            })))));
};
export default PromoHouses;
