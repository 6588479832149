import React, { useEffect, useState } from 'react';
import { getThemeClassName } from '@utils/functions';
import { useTranslation } from 'react-i18next';
import { Icon, Segment } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Box, Button, Modal } from '@mui/material';
import { ModalCloseBtn } from '@elementComponents/ModalCloseBtn';
const CreateBackup = (props) => {
    const { t } = useTranslation();
    const { deviceId } = props;
    const [openModal, setOpenModal] = useState(false);
    const deviceBackups = useSelector((state) => state.device && state.device.backups && state.device.backups.data);
    console.log('deviceBackups=', deviceBackups);
    useEffect(() => {
        console.log('deviceId=', deviceId);
        console.log('openModal=', openModal);
    }, [deviceId, openModal]);
    const onCloseModal = (_event, reason) => {
        if (reason && reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
        }
        setOpenModal(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { variant: 'contained', startIcon: React.createElement(AddOutlinedIcon, null), onClick: () => setOpenModal(true) }, t('locations.createBackup')),
        React.createElement(Modal, { onClose: onCloseModal, open: openModal, className: 'stienen-modal' },
            React.createElement(Box, null,
                React.createElement(ModalCloseBtn, { onClick: () => onCloseModal('', 'closeButtonClick') }),
                React.createElement("h2", { className: 'modal-title' }, t('locations.createBackup')),
                React.createElement("div", { className: 'modal-content' },
                    React.createElement("p", null, t('locations.createBackupText', {
                        date: 'date',
                        name: 'device'
                    })),
                    React.createElement(Segment, { inverted: getThemeClassName() === 'dark-theme', className: 'p1', textAlign: 'center' },
                        React.createElement(Icon, { loading: true, name: 'spinner', size: 'large' }),
                        React.createElement("br", null),
                        React.createElement("p", { className: 'mt1' }, t('locations.pleaseWaitAMoment'))),
                    React.createElement("div", { className: 'modal-action-buttons' },
                        React.createElement(Button, { className: 'text-btn', onClick: () => onCloseModal('', 'closeButtonClick') }, t('main.cancel')),
                        React.createElement(Button, { disabled: true, variant: 'contained' }, t('main.close'))))))));
};
export default CreateBackup;
