import React from 'react';
import { SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import SortableItem from './Sidebar/TabWidgets/SortableItem';
import { Grid } from 'semantic-ui-react';
import { isMobile } from 'react-device-detect';
const TabContent = (props) => {
    const { tabCurrentContent, setTabCurrentContent, overId, dropLinePosition } = props;
    console.log('overId=', overId);
    console.log('dropLinePosition=', dropLinePosition);
    const onClickDelete = (id) => {
        const tabCurrentContentNew = tabCurrentContent.filter(function (obj) {
            return obj.id !== id;
        });
        setTabCurrentContent(tabCurrentContentNew);
    };
    return (React.createElement("div", { className: 'widgets-tab-content' },
        React.createElement(SortableContext, { items: tabCurrentContent, strategy: horizontalListSortingStrategy },
            React.createElement(Grid, null,
                React.createElement(Grid.Row, null, tabCurrentContent.map((content, key) => {
                    return content && (React.createElement(Grid.Column, { key: key, width: isMobile ? 16 : content.width },
                        overId === content.id && dropLinePosition === 'left' && React.createElement("div", { className: 'dropline' },
                            React.createElement("span", { className: 'top' }),
                            React.createElement("span", { className: 'bottom' })),
                        React.createElement(SortableItem, { deleteIcon: true, handle: true, content: content, onClickDelete: onClickDelete }),
                        overId === content.id && dropLinePosition === 'right' && React.createElement("div", { className: 'dropline' },
                            React.createElement("span", { className: 'top' }),
                            React.createElement("span", { className: 'bottom' }))));
                }))))));
};
export default TabContent;
