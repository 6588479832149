import { ClickAwayListener, IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { SketchPicker } from 'react-color';
export const Swatch = ({ color, ...rest }) => (React.createElement("div", { className: 'color-picker' },
    React.createElement("div", { className: 'swatch', ...rest },
        React.createElement("div", { className: 'color', style: { backgroundColor: `#${color}` } }))));
export const ColorPickerComponent = (props) => {
    const [open, setOpen] = useState(false);
    const { name, className } = props;
    const handleTooltipClose = () => {
        setOpen(false);
    };
    const handleTooltipOpen = () => {
        setOpen(true);
    };
    const extraProps = { className: 'colorpicker__sketch' };
    return (React.createElement("div", { className: className },
        React.createElement(Field, { name: name ? name : '' }, ({ input }) => {
            const value = input.value || '008072';
            return (React.createElement(ClickAwayListener, { onClickAway: handleTooltipClose },
                React.createElement("div", null,
                    React.createElement(Tooltip, { arrow: true, placement: 'top', PopperProps: {
                            disablePortal: true,
                        }, onClose: handleTooltipClose, open: open, disableFocusListener: true, disableHoverListener: true, disableTouchListener: true, title: React.createElement(SketchPicker, { ...extraProps, disableAlpha: true, color: value, onChange: color => input.onChange(color.hex.replace('#', '')) }) },
                        React.createElement(IconButton, { onClick: handleTooltipOpen },
                            React.createElement(Swatch, { color: value }))))));
        })));
};
