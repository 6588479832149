import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCookieLocale } from '@utils/locales';
import StienenTable from '@elementComponents/Table';
import { useHistory } from 'react-router-dom';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
const ActiveAlarms = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const activeAlarms = useSelector((state) => state.alarms && state.alarms.active && state.alarms.active.data);
    const lastUpdate = useSelector((state) => state.alarms && state.alarms && state.alarms.active && state.alarms.active.lastUpdate);
    const activeAlarmsData = activeAlarms && activeAlarms.length > 0 ? activeAlarms.map((alarm) => {
        return {
            uuid: alarm.alarmId,
            locationUuid: alarm.gid,
            deviceUuid: alarm.did,
            location: alarm.gatewayName,
            device: alarm.deviceName,
            control: alarm.controlType,
            alarmMessage: alarm.alarmCode,
            active: true
        };
    }) : [];
    const headers = [
        {
            id: 'location',
            numeric: false,
            label: t('alarms.location'),
        },
        {
            id: 'device',
            numeric: false,
            label: t('alarms.device'),
        },
        {
            id: 'control',
            label: t('alarms.control')
        },
        {
            id: 'alarmMessage',
            label: t('alarms.alarmMessage'),
        },
    ];
    const onGoToAlarm = (alarm) => {
        history.push(`/locations/${alarm.locationUuid}/${alarm.deviceUuid}`);
    };
    const lastUpdateDate = lastUpdate
        ? format(parseISO(lastUpdate), 'd MMM', {
            locale: getCookieLocale(),
        }).replace('.', '')
        : format(new Date(), 'd MMM', {
            locale: getCookieLocale(),
        }).replace('.', '');
    const lastUpdateTime = lastUpdate
        ? format(parseISO(lastUpdate), 'HH:mm')
        : format(new Date(), 'HH:mm');
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'content-title justify-content-space-between' },
            React.createElement("div", { className: 'title' },
                React.createElement("h2", null, t('main.activeAlarms'))),
            React.createElement("div", null,
                React.createElement("p", { className: 'last-active-alarms-text' },
                    t('alarms.lastUpdate'),
                    ": \u00A0",
                    React.createElement("span", { className: 'text-transform-capitalize' }, lastUpdateDate ? lastUpdateDate : '-'),
                    "\u00A0",
                    t('main.at'),
                    "\u00A0",
                    lastUpdateTime ? lastUpdateTime : '-'))),
        React.createElement(StienenTable, { showCheckboxes: true, negativeHeaderBorder: activeAlarmsData && activeAlarmsData.length > 0, numberOfRows: 5, headers: headers, rows: activeAlarmsData && activeAlarmsData, defaultOrder: 'location', title: t('main.activeAlarms'), actions: [
                {
                    button: t('main.goTo'),
                    onlyShowIfColumnIsTrue: 'active',
                    onClick: onGoToAlarm
                },
            ] })));
};
export default ActiveAlarms;
