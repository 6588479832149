import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card, CardActionArea, CardContent } from '@mui/material';
import { Grid, GridColumn, GridRow } from 'semantic-ui-react';
import IconAlarms from '!svg-react-loader?name=IconAlarms!assets/svg/icon-alarm.svg';
import IconCalendar from '!svg-react-loader?name=IconCalendar!assets/svg/icon-calendar.svg';
const TabLogs = (props) => {
    const { t } = useTranslation();
    const { device } = props;
    const buttons = [
        {
            label: t('devices.events'),
            labelText: t('devices.events'),
            icon: React.createElement(IconCalendar, null),
            link: `/locations/${device.gatewayId}/${device.id}/logs/events`
        },
        {
            label: t('devices.alarms'),
            labelText: t('devices.alarms'),
            icon: React.createElement(IconAlarms, null),
            link: `/locations/${device.gatewayId}/${device.id}/logs/alarms`
        }
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { stackable: true },
            React.createElement(GridRow, { columns: 3 }, device && buttons.map((button, key) => {
                return (React.createElement(GridColumn, { key: key },
                    React.createElement(Card, { className: 'stienen-card location-card mb2', component: Link, to: button.link },
                        React.createElement("div", { className: 'header-border' }),
                        React.createElement(CardActionArea, null,
                            React.createElement(CardContent, null,
                                React.createElement("div", { className: 'justify-content-center align-items-center' },
                                    React.createElement("div", null,
                                        React.createElement("div", { className: 'justify-content-center' }, button.icon),
                                        React.createElement("p", { className: 'text-transform-capitalize-first-letter' },
                                            React.createElement("b", null, button.label),
                                            React.createElement("br", null),
                                            React.createElement("i", null, button.labelText)))))))));
            })))));
};
export default TabLogs;
