import React from 'react';
import { getThemeClassName } from '@utils/functions';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardDescription, CardHeader, Grid, GridColumn, GridRow, Table, TableBody, TableCell, TableRow } from 'semantic-ui-react';
import IconLocations from '!svg-react-loader?name=IconLocations!assets/svg/icon-location.svg';
import IconCloudOnline from '!svg-react-loader?name=IconCloudOnline!assets/svg/icon-cloud-online.svg';
import IconStatistics from '!svg-react-loader?name=IconStatistics!assets/svg/icon-statistics.svg';
const LocationInfoCards = (props) => {
    const { t } = useTranslation();
    const { locationInfo: { name, usedLicenceKey, online } } = props;
    return (React.createElement(Grid, { stackable: true },
        React.createElement(GridRow, { columns: 3 },
            React.createElement(GridColumn, { className: 'pb2' },
                React.createElement(Card, { fluid: true, className: 'stienen-card fluid-height' },
                    React.createElement(CardContent, null,
                        React.createElement("div", { className: 'header-border' }),
                        React.createElement(CardHeader, { className: 'mb0' },
                            React.createElement("div", { className: 'header-title' },
                                React.createElement("span", null,
                                    React.createElement(IconLocations, null)),
                                React.createElement("h3", null, t('locations.location'))),
                            React.createElement("div", null)),
                        React.createElement(CardDescription, null,
                            React.createElement(Table, { compact: true, unstackable: true, collapsing: true, basic: true, className: `stienen-table sticky-header fluid no-table-border no-background ${getThemeClassName() === 'dark-theme' ? 'inverted' : ''}` },
                                React.createElement(TableBody, null,
                                    React.createElement(TableRow, null,
                                        React.createElement(TableCell, null,
                                            React.createElement("b", null,
                                                t('locations.name'),
                                                ":")),
                                        React.createElement(TableCell, null, name)),
                                    React.createElement(TableRow, null,
                                        React.createElement(TableCell, null,
                                            React.createElement("b", null,
                                                t('locations.licenseKey'),
                                                ":")),
                                        React.createElement(TableCell, null, usedLicenceKey)))))))),
            React.createElement(GridColumn, { className: 'pb2' },
                React.createElement(Card, { fluid: true, className: 'stienen-card fluid-height' },
                    React.createElement(CardContent, null,
                        React.createElement("div", { className: 'header-border' }),
                        React.createElement(CardHeader, { className: 'mb0' },
                            React.createElement("div", { className: 'header-title' },
                                React.createElement("span", null,
                                    React.createElement(IconCloudOnline, null)),
                                React.createElement("h3", null, t('locations.status'))),
                            React.createElement("div", null)),
                        React.createElement(CardDescription, null,
                            React.createElement(Table, { compact: true, unstackable: true, collapsing: true, basic: true, className: `stienen-table fluid no-table-border no-background ${getThemeClassName() === 'dark-theme' ? 'inverted' : ''}` },
                                React.createElement(TableBody, null,
                                    React.createElement(TableRow, null,
                                        React.createElement(TableCell, null,
                                            React.createElement("b", null,
                                                t('locations.status'),
                                                ":")),
                                        React.createElement(TableCell, null, online ? (React.createElement("div", { className: 'text-positive' }, t('locations.online'))) : (React.createElement("div", { className: 'text-negative' }, t('locations.offline'))))))))))),
            React.createElement(GridColumn, { className: 'pb2' },
                React.createElement(Card, { fluid: true, className: 'stienen-card fluid-height' },
                    React.createElement(CardContent, null,
                        React.createElement("div", { className: 'header-border' }),
                        React.createElement(CardHeader, { className: 'mb0' },
                            React.createElement("div", { className: 'header-title' },
                                React.createElement("span", null,
                                    React.createElement(IconStatistics, null)),
                                React.createElement("h3", null, t('locations.statistics'))),
                            React.createElement("div", null)),
                        React.createElement(CardDescription, null)))))));
};
export default LocationInfoCards;
