import { useEffect } from 'react';
import { logoutSuccess, storeUserSuccess } from 'app/login/state/slice';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { clearCustomerSuccess, clearCustomersSuccess } from 'app/customers/state/slice';
import { clearLocationsSuccess } from 'app/locations/state/slice';
import { clearDealerInvitesSuccess } from 'app/dealer/state/slice';
import { clearEventLogSuccess } from 'app/locations/components/Logs/eventLog/state/slice';
import { clearMetricsSuccess, clearProductsSuccess } from 'app/admin/subscription/state/slice';
import { toastrSuccess } from '@elementComponents/Toastr';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
const UserSession = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const auth = useSelector((state) => state.auth && state.auth);
    auth && auth.success && auth.user && auth.user.isServiceAccount && (console.log('HALLO SERVICE GEBRUIKER :)'),
        console.log('JOUW JWT TOKEN IS:'),
        console.log(auth.user.jwtToken));
    const refreshUserSession = async () => {
        console.log('===== REFRESH USER SESSION =====');
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            const currentSession = cognitoUser.signInUserSession;
            const isCustomerAccount = cognitoUser.signInUserSession.idToken.payload['cognito:groups'].includes('customer');
            const isServiceAccount = cognitoUser.signInUserSession.idToken.payload['cognito:groups'].includes('service');
            const isDealerAccount = cognitoUser.signInUserSession.idToken.payload['cognito:groups'].includes('dealer');
            const isMechanicAccount = cognitoUser.signInUserSession.idToken.payload['cognito:groups'].includes('mechanic');
            const isRootAccount = cognitoUser.signInUserSession.idToken.payload['cognito:groups'].includes('root');
            cognitoUser.refreshSession(currentSession.refreshToken, () => {
                const userObject = {
                    userId: cognitoUser.username,
                    email: cognitoUser.attributes.email,
                    emailVerified: cognitoUser.attributes.email_verified,
                    firstName: cognitoUser.attributes.name,
                    lastName: cognitoUser.attributes.family_name,
                    userGroups: cognitoUser.signInUserSession.idToken.payload['cognito:groups'],
                    isCustomerAccount,
                    isServiceAccount,
                    isDealerAccount,
                    isMechanicAccount,
                    isRootAccount,
                    jwtToken: cognitoUser.signInUserSession.idToken.jwtToken
                };
                console.log('REFRESH SUCCESS');
                isServiceAccount && (console.log('HALLO SERVICE GEBRUIKER :)'),
                    console.log('JOUW NIEUWE JWT TOKEN IS:'),
                    console.log(cognitoUser.signInUserSession.idToken.jwtToken));
                dispatch(storeUserSuccess(userObject));
            });
        }
        catch (e) {
            console.error(e);
            Auth.signOut()
                .then(() => {
                toastrSuccess(t('auth.logoutMessageHeader'), t('auth.logoutMessageText'));
                dispatch(logoutSuccess());
                dispatch(clearCustomerSuccess());
                dispatch(clearCustomersSuccess());
                dispatch(clearLocationsSuccess());
                dispatch(clearDealerInvitesSuccess());
                dispatch(clearEventLogSuccess());
                dispatch(clearMetricsSuccess());
                dispatch(clearProductsSuccess());
                history.push('/login');
            })
                .catch((err) => console.log(err));
        }
    };
    useEffect(() => {
        const interval = setInterval(() => {
            auth && auth.success && (console.log('===== REFRESH EVERY 10 MINUTES ====='),
                refreshUserSession());
        }, 1000 * 60 * 10);
        return () => clearInterval(interval);
    }, []);
    return null;
};
export default UserSession;
