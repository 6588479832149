import { toastrError } from '@elementComponents/Toastr';
import { Button } from '@mui/material';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { InputComponent } from '@formComponents/Input';
import { validateAdminAddLocation } from '@validations/admin';
import { AddLocationStep1 } from 'app/locations/state/api';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocations } from 'app/locations/state/slice';
import { MaskedInputComponent } from '@formComponents/MaskedInput';
import { StienenInfoPopup } from '@elementComponents/Popup';
const WizardStepLocation = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { onClickLogout, setActiveStep } = props;
    const customers = useSelector((state) => state.customers && state.customers);
    const customerId = customers && customers.current && customers.current.id;
    const onClickAdd = async (values) => {
        const location = {
            name: values && values.Name,
            licenceKey: values && values.LicenseKey,
            mac: values && values.MacAddress.replace(/-/g, '').toLowerCase(),
        };
        console.log('location=', location);
        try {
            const response = await AddLocationStep1(customerId, location);
            if (response && response.success === true) {
                console.log('response=', response);
                dispatch(fetchLocations(customerId));
                setActiveStep(2);
            }
            else {
                toastrError(t('main.error'), t('main.somethingWentWrong'));
            }
        }
        catch (e) {
            console.error(e);
            toastrError(t('main.error'), t('main.somethingWentWrong'));
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("p", null, t('auth.licenseStepLocationText')),
        React.createElement(Form, { onSubmit: () => { }, validate: validateAdminAddLocation, render: ({ invalid, values }) => {
                return (React.createElement("form", { className: 'mt2' },
                    React.createElement(InputComponent, { name: 'Name', label: t('locations.nameOfTheLocation'), className: 'mb1-5' }),
                    React.createElement(MaskedInputComponent, { name: 'LicenseKey', label: t('locations.licenseKey'), mask: '**-**-**-**-**-**', className: 'mb1-5', endAdornment: React.createElement(StienenInfoPopup, { content: React.createElement("img", { height: '150px', src: require('../../../../../../src/assets/images/license-key-card.png') }) }) }),
                    React.createElement(MaskedInputComponent, { name: 'MacAddress', label: t('locations.macAddress'), mask: '**-**-**-**-**-**', className: 'mb1-5' }),
                    React.createElement("div", { className: 'modal-action-buttons' },
                        React.createElement(Button, { className: 'text-btn', onClick: () => onClickLogout() }, t('main.logout')),
                        React.createElement(Button, { variant: 'contained', className: 'float-right', onClick: () => onClickAdd(values), disabled: invalid
                                || !values
                                || !values.Name
                                || !values.LicenseKey
                                || !values.MacAddress }, t('auth.next')))));
            } })));
};
export default WizardStepLocation;
