import { createSlice } from '@reduxjs/toolkit';
import { getAlarmReference, getControlReference, getFullReference, getNamedReference } from './api';
const initialState = {
    full: [],
    alarm: [],
    control: [],
    named: [],
};
const reference = createSlice({
    name: 'reference',
    initialState,
    reducers: {
        getAlarmReferenceSuccess(state, action) {
            const oldArray = state && state.alarm;
            const newArray = oldArray && oldArray.concat(action.payload);
            state.alarm = newArray;
        },
        getAlarmReferenceFailure(_state, action) {
            console.log(action.payload);
        },
        clearAlarmReferenceSuccess(state) {
            state.alarm = [];
        },
        getControlReferenceSuccess(state, action) {
            const oldArray = state && state.control;
            const newArray = oldArray && oldArray.concat(action.payload);
            state.control = newArray;
        },
        getControlReferenceFailure(_state, action) {
            console.log(action.payload);
        },
        clearControlReferenceSuccess(state) {
            state.control = [];
        },
        getFullReferenceSuccess(state, action) {
            const oldArray = state && state.full;
            const newArray = oldArray && oldArray.concat(action.payload);
            state.full = newArray;
        },
        getFullReferenceFailure(_state, action) {
            console.log(action.payload);
        },
        getNamedReferenceSuccess(state, action) {
            const oldArray = state && state.named;
            const newArray = oldArray && oldArray.concat(action.payload);
            state.named = newArray;
        },
        getNamedReferenceFailure(_state, action) {
            console.log(action.payload);
        },
    }
});
export const { getAlarmReferenceSuccess, getAlarmReferenceFailure, clearAlarmReferenceSuccess, getControlReferenceSuccess, getControlReferenceFailure, clearControlReferenceSuccess, getFullReferenceSuccess, getFullReferenceFailure, getNamedReferenceSuccess, getNamedReferenceFailure, } = reference.actions;
export default reference.reducer;
export const fetchFullReference = (hardware, version) => async (dispatch) => {
    try {
        const referenceList = await getFullReference(hardware, version);
        dispatch(getFullReferenceSuccess(referenceList));
    }
    catch (err) {
        dispatch(getFullReferenceFailure(err && err.message));
    }
};
export const fetchAlarmReference = (hardware, version, language) => async (dispatch) => {
    try {
        const referenceList = await getAlarmReference(hardware, version, language);
        dispatch(getAlarmReferenceSuccess(referenceList));
    }
    catch (err) {
        dispatch(getAlarmReferenceFailure(err && err.message));
    }
};
export const fetchControlReference = (hardware, version, language) => async (dispatch) => {
    try {
        const referenceList = await getControlReference(hardware, version, language);
        dispatch(getControlReferenceSuccess(referenceList));
    }
    catch (err) {
        dispatch(getControlReferenceFailure(err && err.message));
    }
};
export const fetchNamedReference = (hardware, version, language) => async (dispatch) => {
    try {
        const referenceList = await getNamedReference(hardware, version, language);
        dispatch(getNamedReferenceSuccess(referenceList));
    }
    catch (err) {
        dispatch(getNamedReferenceFailure(err && err.message));
    }
};
