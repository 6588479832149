import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Grid, GridColumn, GridRow } from 'semantic-ui-react';
import IconLocations from '!svg-react-loader?name=IconLocations!assets/svg/icon-location.svg';
import { onClickItem } from '@utils/menu';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import { promoMenu } from '@constants/promoMenu';
const PromoLocationsContainer = () => {
    const { t } = useTranslation();
    const locationsSorted = promoMenu;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'content-title justify-content-space-between' },
            React.createElement("div", { className: 'title' },
                React.createElement("h2", null, t('main.locations')))),
        React.createElement(Grid, { stackable: true },
            React.createElement(GridRow, { columns: 3 }, locationsSorted && locationsSorted.map((location, key) => {
                return (React.createElement(GridColumn, { key: key },
                    React.createElement(Card, { className: 'stienen-card location-card mb2', component: Link, to: `/promo-locations/${location.id}`, onClick: () => onClickItem(location.id, 1) },
                        React.createElement("div", { className: 'header-border' }),
                        React.createElement(CardActionArea, null,
                            React.createElement(CardContent, null,
                                React.createElement("div", { className: 'justify-content-center align-items-center' },
                                    React.createElement("div", null,
                                        React.createElement("div", { className: 'justify-content-center' },
                                            React.createElement(IconLocations, null)),
                                        React.createElement("p", { className: 'text-transform-capitalize-first-letter' },
                                            React.createElement("b", null, location.name),
                                            React.createElement("br", null),
                                            React.createElement("i", null,
                                                location.subMenu && location.subMenu.length,
                                                " Houses")))))))));
            })))));
};
export default PromoLocationsContainer;
