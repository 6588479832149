import { Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
const DeviceStatusDot = (props) => {
    const { t } = useTranslation();
    const { online } = props;
    return online ? (React.createElement(Tooltip, { arrow: true, placement: 'top', title: t('devices.online') },
        React.createElement("span", { className: 'device-status-dot positive' }))) : !online ? (React.createElement(Tooltip, { arrow: true, placement: 'top', title: t('devices.offline') },
        React.createElement("span", { className: 'device-status-dot negative' }))) : null;
};
export default DeviceStatusDot;
