import { Alert, AlertTitle } from '@mui/material';
import React from 'react';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
export const StienenMessage = (props) => {
    const { header, noIcon, variant, description, type, className } = props;
    return (React.createElement(Alert, { variant: variant ? variant : 'outlined', severity: type, className: className, icon: noIcon && false, iconMapping: {
            success: React.createElement(CheckCircleOutlineRoundedIcon, null),
            error: React.createElement(ErrorOutlineRoundedIcon, null),
            warning: React.createElement(WarningAmberRoundedIcon, null),
            info: React.createElement(HelpOutlineRoundedIcon, null)
        } },
        React.createElement("div", null,
            header && React.createElement(AlertTitle, null, header),
            description)));
};
