import { listAnimals, listClimate, listFeed } from '@constants/widgets';
import { getDutchNumber, isNumber } from '@utils/amounts';
import React from 'react';
import { List, ListContent, ListItem } from 'semantic-ui-react';
const ListWidget = (props) => {
    const { content: { title, type } } = props;
    const list = title === 'Animals' && type === 'list' ? listAnimals
        : title === 'Climate' && type === 'list' ? listClimate
            : title === 'Food' && type === 'list' ? listFeed
                : [];
    return (React.createElement(List, { className: 'widget-list' }, list && list.length > 0 && list.map((item, key) => {
        return (React.createElement(ListItem, { key: key },
            React.createElement(ListContent, { floated: 'right' },
                isNumber(item.value) ? getDutchNumber(item.value) : item.value,
                item.type && ` ${item.type}`,
                item.value2 && ` / ${isNumber(item.value2) ? getDutchNumber(item.value2) : item.value2}${item.type2 && ` ${item.type2}`}`),
            React.createElement(ListContent, { className: 'label' }, item.label)));
    })));
};
export default ListWidget;
