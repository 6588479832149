import React from 'react';
import { NavLink } from 'react-router-dom';
import IconAlarms from '!svg-react-loader?name=IconAlarms!assets/svg/icon-alarm.svg';
import { useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
const AlarmMenuItem = (props) => {
    const { pagePath, sidebar, onClickSidebarBtn } = props;
    const alarms = useSelector((state) => state.alarms && state.alarms.active && state.alarms.active.data);
    const numberOfAlarms = alarms && alarms.length;
    const hasAlarms = numberOfAlarms > 0;
    return (React.createElement("li", null,
        React.createElement(IconButton, { component: NavLink, to: {
                pathname: '/alarms',
                state: { prevPath: pagePath, prevSidebar: sidebar },
            }, onClick: () => onClickSidebarBtn(false) },
            hasAlarms &&
                React.createElement("span", { className: 'notification-alarm-number' }, numberOfAlarms),
            React.createElement(IconAlarms, null))));
};
export default AlarmMenuItem;
