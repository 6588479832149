import { combineReducers } from '@reduxjs/toolkit';
import auth from '../app/login/state/slice';
import customers from '../app/customers/state/slice';
import users from '../app/admin/users/state/slice';
import dealers from '../app/dealer/state/slice';
import locations from '../app/locations/state/slice';
import subscription from '../app/admin/subscription/state/slice';
import eventLog from '../app/locations/components/Logs/eventLog/state/slice';
import analytics from '../app/analytics/state/slice';
import alarms from '../app/alarms/state/slice';
import reference from '../app/reference/state/slice';
import device from '../app/device/state/slice';
import widgets from '../app/widgets/state/slice';
const combinedReducer = combineReducers({
    analytics,
    auth,
    alarms,
    customers,
    dealers,
    device,
    eventLog,
    locations,
    reference,
    subscription,
    users,
    widgets
});
const rootReducer = (state, action) => {
    if (action.type === 'auth/logoutSuccess') {
        state = undefined;
    }
    return combinedReducer(state, action);
};
export default rootReducer;
