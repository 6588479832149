import { createSlice } from '@reduxjs/toolkit';
import { getAnalytics } from './api';
const initialState = {
    columns: [],
    list: {
        data: [],
        loading: false,
        isLoaded: false,
        error: ''
    }
};
const analytics = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
        getAnalyticsStart(state) {
            state.list.loading = true;
        },
        getAnalyticsSuccess(state, action) {
            state.list.data = action.payload;
            state.list.loading = false;
            state.list.isLoaded = true;
            state.list.error = '';
        },
        getAnalyticsFailure(state, action) {
            state.list.loading = false;
            state.list.isLoaded = false;
            state.list.error = action.payload;
            console.log(action.payload);
        },
        clearAnalyticsSuccess(state) {
            state.list.data = [],
                state.list.loading = false,
                state.list.isLoaded = false,
                state.list.error = '';
        },
        storeColumnsSuccess(state, action) {
            state.columns = action.payload;
        },
        storeNewTabSuccess(state, action) {
            const oldArray = state && state.columns;
            const newArray = oldArray && oldArray.concat(action.payload);
            state.columns = newArray;
        }
    }
});
export const { getAnalyticsStart, getAnalyticsSuccess, getAnalyticsFailure, clearAnalyticsSuccess, storeColumnsSuccess, storeNewTabSuccess } = analytics.actions;
export default analytics.reducer;
export const fetchAnalytics = (device, start, end, interval) => async (dispatch) => {
    try {
        dispatch(getAnalyticsStart());
        const analyticsList = await getAnalytics(device, start, end, interval);
        dispatch(getAnalyticsSuccess(analyticsList));
    }
    catch (err) {
        dispatch(getAnalyticsFailure(err && err.message));
    }
};
