import { InputAdornment } from '@mui/material';
import React from 'react';
import { showErrorOnBlur, TextField } from 'mui-rff';
import { Field } from 'react-final-form';
import InputMask from 'react-input-mask';
export const MaskedInputComponent = (props) => {
    const { name, label, type, startAdornment, endAdornment, required, fullWidth, disabled, placeholder, className, mask } = props;
    return (React.createElement("div", { className: className },
        React.createElement(Field, { name: name ? name : '', parse: (value) => {
                return (value.toUpperCase());
            } }, ({ input, meta }) => {
            return (React.createElement(InputMask, { disabled: false, mask: mask, maskPlaceholder: null, alwaysShowMask: false, ...input },
                React.createElement(TextField, { ...input, fullWidth: fullWidth, type: type ? type : 'text', slotProps: {
                        input: {
                            startAdornment: startAdornment && React.createElement(InputAdornment, { position: 'start' }, startAdornment),
                            endAdornment: endAdornment && React.createElement(InputAdornment, { position: 'end' }, endAdornment)
                        }
                    }, disabled: disabled, required: required, placeholder: placeholder, label: label ? label : false, showError: showErrorOnBlur, error: meta.error && meta.touched, helperText: meta.error && meta.touched && meta.error })));
        })));
};
