import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import IconHome from '!svg-react-loader?name=IconHome!assets/svg/icon-barn2.svg';
import IconAdmin from '!svg-react-loader?name=IconAdmin!assets/svg/icon-admin.svg';
import IconWidgets from '!svg-react-loader?name=IconWidgets!assets/svg/icon-widget.svg';
import IconLocations from '!svg-react-loader?name=IconLocations!assets/svg/icon-location.svg';
import Cookies from 'universal-cookie';
import AlarmMenuItem from './AlarmMenuItem';
import IconStatistics from '!svg-react-loader?name=IconStatistics!assets/svg/icon-statistics.svg';
import { IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
const MainMenu = (props) => {
    const cookies = new Cookies();
    const history = useHistory();
    const auth = useSelector((state) => state.auth && state.auth);
    const isCustomerAccount = auth && auth.user && auth.user.isCustomerAccount;
    const isServiceAccount = auth && auth.user && auth.user.isServiceAccount;
    const [pagePath, setPagePath] = useState('');
    const [sidebar, setSidebar] = useState(cookies.get('sidebar'));
    const { onClickSidebarBtn } = props;
    useEffect(() => {
        setPagePath(location.pathname);
        history.listen((location) => {
            setPagePath(location.pathname);
            setSidebar(cookies.get('sidebar'));
        });
    }, [history]);
    const containsCustomerSelect = location.pathname.includes('/select-customer');
    const containsDealerLogin = location.pathname.includes('/dealer-login');
    return (React.createElement("div", { id: 'menu' }, !containsCustomerSelect && !containsDealerLogin &&
        React.createElement(React.Fragment, null,
            React.createElement("ul", { className: 'parent-menu', onClick: () => onClickSidebarBtn(false) },
                React.createElement("li", null,
                    React.createElement(IconButton, { exact: true, component: NavLink, to: {
                            pathname: '/dashboard',
                            state: { prevPath: pagePath, prevSidebar: sidebar },
                        } },
                        React.createElement(IconHome, null))),
                React.createElement(AlarmMenuItem, { pagePath: pagePath, sidebar: sidebar, onClickSidebarBtn: onClickSidebarBtn })),
            React.createElement("ul", { className: 'company-menu' },
                (isCustomerAccount || isServiceAccount) && (React.createElement("li", null,
                    React.createElement(IconButton, { component: NavLink, to: {
                            pathname: '/admin',
                            state: { prevPath: pagePath, prevSidebar: sidebar },
                        }, onClick: () => onClickSidebarBtn(true) },
                        React.createElement(IconAdmin, null)))),
                React.createElement("li", null,
                    React.createElement(IconButton, { component: NavLink, to: {
                            pathname: '/widgets',
                            state: { prevPath: pagePath, prevSidebar: sidebar },
                        }, onClick: () => onClickSidebarBtn(false) },
                        React.createElement(IconWidgets, null))),
                React.createElement("li", null,
                    React.createElement(IconButton, { component: NavLink, to: {
                            pathname: '/analytics',
                            state: { prevPath: pagePath, prevSidebar: sidebar },
                        }, onClick: () => onClickSidebarBtn(false) },
                        React.createElement(IconStatistics, null))),
                React.createElement("li", null,
                    React.createElement(IconButton, { component: NavLink, to: {
                            pathname: '/locations',
                            state: { prevPath: pagePath, prevSidebar: sidebar },
                        }, onClick: () => onClickSidebarBtn(true) },
                        React.createElement(IconLocations, null))),
                React.createElement("li", null,
                    React.createElement(IconButton, { component: NavLink, to: {
                            pathname: '/promo-locations',
                            state: { prevPath: pagePath, prevSidebar: sidebar },
                        }, onClick: () => onClickSidebarBtn(true) },
                        React.createElement(IconLocations, null)))))));
};
export default MainMenu;
