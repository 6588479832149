import React, { forwardRef } from 'react';
import IconDragIndicator from '!svg-react-loader?name=IconDragIndicator!assets/svg/icon-drag-indicator.svg';
import IconDelete from '!svg-react-loader?name=IconDelete!assets/svg/icon-delete.svg';
import { Form, FormSpy } from 'react-final-form';
import { validateWidgetsTab } from '@validations/widgets';
import { InputComponent } from '@formComponents/Input';
import slugify from 'slugify';
import { ColorPickerComponent } from '@formComponents/ColorPicker';
import { IconButton } from '@mui/material';
const TabsListItem = forwardRef(({ dragHandleProps, snapshot, setTabItems, tabItems, onDeleteTab, item, showColor, showInput, showDelete, ...props }, ref) => {
    const onChangeInput = (item, newValue) => {
        const newItem = [{
                index: item.index,
                uuid: item.uuid,
                color: item.color,
                name: newValue,
                friendlyName: slugify(newValue, { lower: true })
            }];
        const newArray = tabItems.map((obj) => newItem.find(o => o.uuid === obj.uuid) || obj);
        setTabItems(newArray);
    };
    const onChangeColor = (item, newValue) => {
        const newItem = [{
                index: item.index,
                uuid: item.uuid,
                color: newValue,
                name: item.name,
                friendlyName: item.name
            }];
        const newArray = tabItems.map((obj) => newItem.find(o => o.uuid === obj.uuid) || obj);
        setTabItems(newArray);
    };
    return (React.createElement("li", { ref: ref, ...props, className: 'drag-and-drop-list-item ' + (snapshot.isDragging ? 'hovering' : '') },
        React.createElement("span", { className: 'drag-indicator', ...dragHandleProps },
            React.createElement(IconDragIndicator, null)),
        React.createElement("span", { className: 'name' }, showInput
            ?
                React.createElement(React.Fragment, null,
                    React.createElement(Form, { onSubmit: () => { }, validate: validateWidgetsTab, initialValues: {
                            TabName: item.name
                        }, render: () => (React.createElement("form", null,
                            React.createElement(InputComponent, { name: 'TabName', value: item.name }),
                            React.createElement(FormSpy, { onChange: (props) => {
                                    onChangeInput(item, props.values.TabName);
                                } }))) }))
            : React.createElement("span", { className: 'text' }, item.name)),
        showColor &&
            React.createElement("span", { className: 'color-block' },
                React.createElement(Form, { onSubmit: () => { }, initialValues: {
                        TabColor: item.color
                    }, render: () => (React.createElement("form", null,
                        React.createElement(ColorPickerComponent, { name: 'TabColor' }),
                        React.createElement(FormSpy, { onChange: (props) => {
                                onChangeColor(item, props.values.TabColor);
                            } }))) })),
        showDelete && tabItems && tabItems.length > 1 &&
            React.createElement("span", { className: 'delete' },
                React.createElement(IconButton, { onClick: () => onDeleteTab(item.uuid) },
                    React.createElement(IconDelete, null)))));
});
TabsListItem.displayName = 'TabsListItem';
export default TabsListItem;
